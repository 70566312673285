import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rm-repository',
  templateUrl: './rm-repository.component.html',
  styleUrls: ['./rm-repository.component.scss']
})
export class RmRepositoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
