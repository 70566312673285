import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public showProfile = false;
  public showProfileAnimation = false;
  public showNotification = false;
  public showNotificationAnimation = false;
  // public title: any;
  public title = 'Portfolio';
  public dateSelections = ['Since Inception Till Date', 'Last Financial Year', 'Current Year Till Date', 'Custom']
  public membersProfile = [
    { shortForm: 'KK', name: 'Krisan Krishnamurthy', profiletype: 'head' },
    { shortForm: 'SK', name: 'Shreya Krishnamurthy', profiletype: 'spouse' },
    { shortForm: 'RK', name: 'Ray Krishnamurthy', profiletype: 'brother' },
    { shortForm: 'MK', name: 'Milan Krishnamurthy', profiletype: 'daughter' }
  ]

  constructor(
    public authService: AuthService,
    public titleService: Title
  ) { }

  ngOnInit() {
  }

  openProfile() {
    this.showProfile = true;
    this.showProfileAnimation = true;
  }

  openNotification() {
    this.showNotification = true;
    this.showNotificationAnimation = true;
  }

  closeProfile() {
    this.showProfileAnimation = false;
    setTimeout(() => {
      this.showProfile = false;
    }, 500);
  }

  closeNotification() {
    this.showNotificationAnimation = false;
    setTimeout(() => {
      this.showNotification = false;
    }, 500);
  }

  titleComponent(title: any) {
    this.title = title;
    this.setTitle(title);

  }
  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
    this.authService.headerTitle = newTitle;
  }

}
