import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-alternate-equity-performance',
  templateUrl: './alternate-equity-performance.component.html',
  styleUrls: ['./alternate-equity-performance.component.scss', '../alternate-equity.component.scss']
})
export class AlternateEquityPerformanceComponent implements OnInit {

  public Highcharts = Highcharts;
  public marketCap: any;

  constructor() { }

  ngOnInit() {
    this.genrateMarketCapGraph();
  }

  genrateMarketCapGraph() {
    this.marketCap = {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        // align: 'top',
        type: 'pie',
        spacing: [0, 0, 0, 0],
        // width: 336,

        style: {
          fontFamily: 'IBMPlexSans'
        },
        height: 300
      },
      title: {
        verticalAlign: 'middle',
        y: -38,
        // floating: true,
        useHTML: true,
        text: ''
      },
      tooltip: {
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-value">₹40.53 Cr</span><br /><span class="tooltip-name font-size-10">21% Large Cap</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          cursor: 'pointer',
          colors: [
            '#801E41',
            '#BE5E81',
            '#DA789B',
            '#E8A3BB',
          ],
          // enableMouseTracking: false,
          dataLabels: {
            enabled: false
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '55%'],
          showInLegend: true,
          size: '110%',
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [{
        type: 'pie',
        data: [
          { name: 'Growth Stage Direct Deals', y: 45 },
          { name: 'Early Stage Direct Deals', y: 25 },
          { name: 'VC Fund (Early Stage)', y: 15 },
          { name: 'PE Fund (Growth-Late)', y: 14 },
        ]
      }],
      responsive: {
        rules: [
          {
            condition: {
              minWidth: 345
            },
            chartOptions: {
              plotOptions: {
                pie: { center: ['50%', '50%'] }
              }
            }
          },
          {
            condition: {
              maxWidth: 250
            },
            chartOptions: {
              plotOptions: {
                chart: {
                  height: 250
                },
                pie: { center: ['50%', '50%'] }
              }
            }
          }
        ]
      }
    }
  }

}
