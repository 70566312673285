import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equity',
  templateUrl: './equity.component.html',
  styleUrls: ['./equity.component.scss']
})
export class EquityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
