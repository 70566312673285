import { Component, OnInit, ViewChild } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll/lib';

@Component({
  selector: 'app-insights-details',
  templateUrl: './insights-details.component.html',
  styleUrls: ['./insights-details.component.scss', '../insights.component.scss']
})
export class InsightsDetailsComponent implements OnInit {

  public showLeftScrollButton = true;
  public showRightScrollButton = true;

  @ViewChild('articlecard', { read: DragScrollComponent }) article: any;

  constructor() { }

  ngOnInit() {
  }

  moveLeft() {
    this.article.moveLeft();
  }

  moveRight(rightdata) {
    this.article.moveRight();
  }

  onLeftBound(evn: any) {
    if (evn) {
      this.showLeftScrollButton = true;
    } else {
      this.showLeftScrollButton = false;
    }
  }

  onRightBound(evn: any) {
    if (evn) {
      this.showRightScrollButton = true;
    } else {
      this.showRightScrollButton = false;
    }
  }

}
