import { Component, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { DragScrollComponent } from 'ngx-drag-scroll';

@Component({
  selector: 'app-rm-dashboard',
  templateUrl: './rm-dashboard.component.html',
  styleUrls: ['./rm-dashboard.component.scss']
})
export class RmDashboardComponent implements OnInit {

  public Highcharts = Highcharts;
  public assetValueGraph: any;
  public assetValueGraphTotalAUM: any;
  public isHeddaway = false;
  public moreClicked = false;
  public movementGraph: any;
  public amcIssuerGraph: any;
  public liquidityScheduleGraph: any;

  // existing clients
  public topGainersGraph: any;

  // Top Products
  public topAMCData = [
    { name: 'ICICI Credit Risk Fund', allocation: '21' },
    { name: 'Nippon FMP S 31', allocation: '23' },
    { name: 'IDFC Ultra Short Term', allocation: '32' },
    { name: 'Mirae Asset Large Cap', allocation: '11' },
    { name: 'Avendus Long Short Fund', allocation: '17.5' },
    { name: 'Bharat Bond ETD', allocation: '24' },
    { name: 'Motilal Mid Cap 30', allocation: '13' },
    { name: 'HDFC Liquid', allocation: '18' },
    { name: 'Axis Long Term Equity', allocation: '22' },
    { name: 'Kotak Flexicap', allocation: '15' },
  ]


  public amcIssuerData = [
    {
      name: "",
      y: 0,
      color: '#fff',
      pointWidth: 20,
      assettype: null
    },
    {
      name: "Avendus",
      y: 10,
      color: '#4891FF',
      assettype: 'equity'
    },
    {
      name: "ICICI",
      y: 7,
      color: '#ABB7C8',
      assettype: 'debt'
    },
    {
      name: "NIPPON",
      y: 5,
      color: '#ABB7C8',
      assettype: 'debt'
    },
    {
      name: "IDFC",
      y: 5,
      color: '#000000',
      assettype: 'alternate'
    },
    {
      name: "MIRAE",
      y: 3.8,
      color: '#4891FF',
      assettype: 'equity'
    },
    {
      name: "C&E",
      y: 3.5,
      color: '#000000',
      assettype: 'alternate'
    },
    {
      name: "Edelwiss",
      y: 2.2,
      color: '#ABB7C8',
      assettype: 'debt'
    },
    {
      name: "Motilal",
      y: 2.2,
      color: '#4891FF',
      assettype: 'equity'
    },
    {
      name: "hdfc",
      y: 1.9,
      color: '#ABB7C8',
      assettype: 'debt'
    },
    {
      name: "",
      y: 1,
      color: '#fff',
      pointWidth: 40,
      assettype: null
    }
  ];
  public amcData = this.amcIssuerData;
  public amcDataDuplicate = this.amcIssuerData;
  public equitySelect = true;
  public debtSelect = true;
  public alternateSelect = true;
  public showLeftScrollButton = true;
  public showRightScrollButton = true;
  public showLeftScrollButtonInvestment = true;
  public showRightScrollButtonInvestment = true;
  public showLeftScrollButtonMeeting = true;
  public showRightScrollButtonMeeting = true;

  @ViewChild('portfoliocard', { read: DragScrollComponent }) pc: any;
  @ViewChild('investmentdetails', { read: DragScrollComponent }) ind: any;
  @ViewChild('meetingcard', { read: DragScrollComponent }) mc: any;

  constructor() { }

  ngOnInit() {
    this.genrateAllocationSummary();
    this.genrateMovementGraphSummary();
    this.genrateamcIssuerGraphSummary();
    this.genrateLiquidityScheduleGraphSummary();
    this.genrateTopGainersGraphGraph();
    this.genrateAllocationSummaryTotalAum();
  }

  genrateAllocationSummary() {
    this.assetValueGraph = {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        backgroundColor: null,
        // align: 'top',
        type: 'pie',
        // spacing: [0, 0, 0, 0],
        // spacingBottom: 60,
        // marginLeft: 5,
        // width: 350,
        height: 434,
        style: {
          fontFamily: 'IBMPlexSans'
        },
        // height: 350

        events: {
          load: function () {
            const titleHeight = this.title.getBBox().height;
            const titleWidth = this.title.getBBox().width;
            this.title.attr({
              y: this.plotHeight / 3,
              x: this.plotWidth / 2,
            });
          },
          redraw: function () {
            const titleHeight = this.title.getBBox().height;
            const titleWidth = this.title.getBBox().width;
            this.title.attr({
              y: this.plotHeight / 3,
              x: this.plotWidth / 2,
            });
          },
        },
      },
      title: {
        verticalAlign: 'middle',
        align: 'center',
        y: -75,
        // floating: true,
        useHTML: true,
        text: '<span class="pie-title-container"><span class="pie-chart-name">Asset Value</span><br /><span class="pie-chart-value">₹2,450.89 Cr</span></span>'
      },
      tooltip: {
        shape: 'rect',
        shadow: false,
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-value">₹81.06 Cr</span><br /><span class="tooltip-name font-size-12">15% Equity</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      legend: {
        symbolHeight: 10,
        symbolWeight: 9.8,
        symbolRadius: 5,
        itemMarginBottom: 2,
        // layout: 5 < 3 ? 'vertical' : 'horizontal',
        layout: 'horizontal',
        align: 'bottom',
        verticalAlign: 'bottom',
        borderWidth: 0,
        y: -4,
        x: -17,
        // x: 10,
        width: 300,
        // itemWidth: 300,
        useHTML: true,
        labelFormatter: function () {
          return '<span class="legend-value">' + this.y + '%' + ' ' + '</span> <span class="legend-name">  ' + this.name + '</span><br/>';
        }
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          cursor: 'pointer',
          colors: [
            '#FB7648',
            '#EAC2D0',
            '#556594',
            '#A2CFEE',
            '#8BCBA7',
            '#DFD7D7'
          ],
          // enableMouseTracking: false,
          dataLabels: {
            enabled: false
          },
          center: ['50%', '35%'],
          showInLegend: true,
          innerSize: '91%',
          size: '78.2%',
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [{
        type: 'pie',
        innerSize: '92%',
        data: [
          { name: 'Equity', y: 15 },
          { name: 'Alternate Equity', y: 24 },
          { name: 'Debt', y: 18 },
          { name: 'Alternate Debt', y: 20 },
          { name: 'Cash', y: 14 },
          { name: 'Alternate', y: 9 }
        ]
      }],
    }
  }

  genrateAllocationSummaryTotalAum() {
    this.assetValueGraphTotalAUM = {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        backgroundColor: null,
        // align: 'top',
        type: 'pie',
        // spacing: [0, 0, 0, 0],
        // spacingBottom: 60,
        // marginTop: -70,
        // width: 350,
        height: 434,
        style: {
          fontFamily: 'IBMPlexSans'
        },
        // height: 350

        events: {
          load: function () {
            const titleHeight = this.title.getBBox().height;
            const titleWidth = this.title.getBBox().width;
            this.title.attr({
              y: this.plotHeight / 3,
              x: this.plotWidth / 2,
            });
          },
          redraw: function () {
            const titleHeight = this.title.getBBox().height;
            const titleWidth = this.title.getBBox().width;
            this.title.attr({
              y: this.plotHeight / 3,
              x: this.plotWidth / 2,
            });
          },
        },
      },
      title: {
        verticalAlign: 'middle',
        align: 'center',
        y: -75,
        // floating: true,
        useHTML: true,
        text: '<span class="pie-title-container"><span class="pie-chart-name">Asset Value</span><br /><span class="pie-chart-value">₹16,450 Cr</span></span>'
      },
      tooltip: {
        shape: 'rect',
        shadow: false,
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-value">₹81.06 Cr</span><br /><span class="tooltip-name font-size-12">15% Equity</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      legend: {
        symbolHeight: 10,
        symbolWeight: 9.8,
        symbolRadius: 5,
        itemMarginBottom: 2,
        // layout: 5 < 3 ? 'vertical' : 'horizontal',
        layout: 'horizontal',
        align: 'bottom',
        verticalAlign: 'bottom',
        borderWidth: 0,
        y: -20,
        x: -17,
        // x: 10,
        width: 300,
        // itemWidth: 300,
        useHTML: true,
        labelFormatter: function () {
          return '<span class="legend-value">' + this.y + '%' + ' ' + '</span> <span class="legend-name">  ' + this.name + '</span><br/>';
        }
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          cursor: 'pointer',
          colors: [
            '#9A827E',
            '#BDADAA',
            '#D4C7C5',
            '#DECFCC',
            '#EFEAEA',
            // '#DFD7D7'
          ],
          // enableMouseTracking: false,
          dataLabels: {
            enabled: false
          },
          center: ['50%', '35%'],
          showInLegend: true,
          innerSize: '91%',
          size: '78.2%',
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [{
        type: 'pie',
        innerSize: '92%',
        data: [
          { name: 'Suresh Narayan', y: 15 },
          { name: 'Rajesh Shah', y: 24 },
          { name: 'Sameer Desai', y: 18 },
          { name: 'Rajan Narayanam', y: 29 },
          { name: 'Alok Shah', y: 14 },
          // { name: 'Alternate', y: 9 }
        ]
      }],
    }
  }

  genrateMovementGraphSummary() {
    this.movementGraph = {
      chart: {
        type: 'bar',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        spacing: [40, 0, 0, 24],
        style: {
          fontFamily: 'IBMPlexSans'
        },
        width: 350
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['YTD', 'SI', '3Y', '1Y', '6M', '1M'],
        title: {
          text: null
        },
        lineColor: '#C8C8C8',
        useHTML: true,
        labels: {
          // step: 2,
          // lineWidth: 0,
          x: -24,

          align: 'left',
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
        labels: {
          overflow: 'justify',
          formatter() {
            if (this.value !== 0) {
              return this.value + '%';
            }
          }
        },
        gridLineWidth: 0,
        lineColor: '#C8C8C8',
        lineWidth: 1
      },
      tooltip: {
        valueSuffix: ' millions'
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'top',
        x: 0,
        y: -40,
        floating: true,
        borderWidth: 0,
        enabled: true,
        symbolHeight: 12,
        symbolWidth: 12,
        symbolRadius: 2,

      },
      series: [{
        color: '#ABB7C8',
        name: 'IRR',
        data: [12.6, 32.8, 18.7, 15, 48.9, 28.6]
      },
      {
        color: '#4891FF',
        name: 'BMIRR',
        data: [20.3, 52.9, 9.8, 20, 35, 40]
      }
      ]
    }
  }

  genrateamcIssuerGraphSummary() {
    this.amcIssuerGraph = {
      chart: {
        type: 'column',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        spacing: [0, 0, 0, 0],
        style: {
          fontFamily: 'IBMPlexSans'
        },
        width: 750,
        height: 300
      },
      title: {
        text: ''
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        type: 'category',
        style: {
          color: '#757575',
          fontSize: '10px'
        }
      },
      yAxis: {
        title: {
          align: 'low',
          offset: 0,
          text: 'in ₹ Cr',
          rotation: 0,
          y: 18,
          // x:
          style: {
            // fontSize: '12px',
            color: '#757575'
          }
        },
        labels: {
          x: 4,
          y: -5,
          align: 'left',
          style: {
            color: '#757575',
            fontSize: '12px'
          }
        }

      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          pointWidth: 40,
          borderWidth: 0,
          dataLabels: {
            enabled: false
          }
        }
      },

      series: [
        {
          name: "Browsers",
          colorByPoint: true,
          data: this.amcData
        }
      ]
    }
  }

  genrateLiquidityScheduleGraphSummary() {
    this.liquidityScheduleGraph = {
      chart: {
        type: 'column',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        backgroundColor: '#fff',
        spacing: [0, 20, 0, 20],
        style: {
          fontFamily: 'IBMPlexSans'
        },
        width: 378,
        height: 250
      },
      title: {
        text: ''
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        type: 'category',
        style: {
          color: '#757575',
          fontSize: '10px'
        },
        lineColor: '#C8C8C8',
        lineWidth: 0.5
      },
      yAxis: {
        title: {
          text: ''
        },
        gridLineColor: '#C8C8C8',
        gridLineWidth: 0.5,
        labels: {
          x: 4,
          y: -5,
          align: 'left',
          style: {
            color: '#757575',
            fontSize: '12px'
          }
        }

      },
      tooltip: {
        formatter: function (data: any) {
          return 'Value: ' + this.y;
        },
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          // pointWidth: 40,
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
        }
      },

      series: [
        {
          color: '#4891FF',
          data: [
            { name: '', y: 0 },
            { name: 'AVAILABLE', y: 31 },
            { name: '2001', y: 22 },
            { name: '2002', y: 13 },
            { name: '2003', y: 19 }
          ]
        }
      ]
    }
  }

  equitySelected() {
    this.equitySelect = !this.equitySelect;
    if (this.equitySelect == false) {
      this.amcData = this.amcData.filter(item => item.assettype !== 'equity')
    } else {
      this.amcData = this.amcDataDuplicate;
    }
    this.genrateamcIssuerGraphSummary();
  }

  debtSelected() {
    this.debtSelect = !this.debtSelect;
    if (this.debtSelect == false) {
      this.amcData = this.amcData.filter(item => item.assettype !== 'debt')
    } else {
      this.amcData = this.amcDataDuplicate;
    }
    this.genrateamcIssuerGraphSummary();
  }

  alternateSelected() {
    this.alternateSelect = !this.alternateSelect;
    if (this.alternateSelect == false) {
      this.amcData = this.amcData.filter(item => item.assettype !== 'alternate')
    } else {
      this.amcData = this.amcDataDuplicate;
    }
    this.genrateamcIssuerGraphSummary();
  }

  moveLeft(leftdata) {
    console.log(leftdata);
    switch (leftdata) {
      case 'pc':
        this.pc.moveLeft();
        break;
      case 'ind':
        this.ind.moveLeft();
        break;
      case 'meeting':
        this.mc.moveLeft();
        break;
      default:
        break;
    }
    // this.pc.moveLeft();
  }

  moveRight(rightdata) {
    console.log(rightdata);
    switch (rightdata) {
      case 'pc':
        this.pc.moveRight();
        break;
      case 'ind':
        this.ind.moveRight();
        break;
      case 'meeting':
        this.mc.moveRight();
        break;
      default:
        break;
    }
    // this.pc.moveRight();
  }


  onLeftBound(evn: any) {
    if (evn) {
      this.showLeftScrollButton = true;
    } else {
      this.showLeftScrollButton = false;
    }
  }

  onRightBound(evn: any) {
    if (evn) {
      this.showRightScrollButton = true;
    } else {
      this.showRightScrollButton = false;
    }
  }

  onLeftBoundInvestment(evn: any) {
    if (evn) {
      this.showLeftScrollButtonInvestment = true;
    } else {
      this.showLeftScrollButtonInvestment = false;
    }
  }

  onRightBoundInvestment(evn: any) {
    if (evn) {
      this.showRightScrollButtonInvestment = true;
    } else {
      this.showRightScrollButtonInvestment = false;
    }
  }

  onLeftBoundMeeting(evn: any) {
    if (evn) {
      this.showLeftScrollButtonMeeting = true;
    } else {
      this.showLeftScrollButtonMeeting = false;
    }
  }

  onRightBoundMeeting(evn: any) {
    if (evn) {
      this.showRightScrollButtonMeeting = true;
    } else {
      this.showRightScrollButtonMeeting = false;
    }
  }

  onAllFundCheckboxChange(e: any) {
    if (e.target.checked == true) {
      this.isHeddaway = true;
    } else {
      this.isHeddaway = false;
    }
  }

  moreBtnClicked() {
    this.moreClicked = !this.moreClicked
  }


  // TopGainers graph

  genrateTopGainersGraphGraph() {
    this.topGainersGraph = {
      chart: {
        type: 'area',
        width: 56,
        height: 30,
        spacing: [0, 0, 0, 0],
        backgroundColor: null
      },
      yAxis: {
        startOnTick: false,
        min: -5,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: null
        },
        ordinal: false,
        minorTickLength: 0,
        tickLength: 0,
        crosshair: false
      },
      xAxis: {
        margin: 10,
        lineWidth: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: null
        },
        ordinal: false,
        minorTickLength: 0,
        tickLength: 0,
        crosshair: false
      },
      title: {
        useHTML: true,
        text: ''
      },
      subtitle: { text: null },
      tooltip: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      exporting: { enabled: false },
      plotOptions: {
        series: {
          lineWidth: 0.5,
          marker: {
            enabled: false,
          },
          allowPointSelect: false,
          fillColor: {
            linearGradient: [0, 0, 0, 45],
            stops: [
              [0, 'rgba(143, 212, 139, 1)'],
              [1, 'rgba(143, 212, 139, 0)'],
            ]
          },
        }
      },
      series: [{
        color: '#8FD48B',
        data: [6, 15, 18, 20, 9, 22, 19, 20, 12, 6]
      }]
    }
  }


}
