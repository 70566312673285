import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-debt-quants',
  templateUrl: './debt-quants.component.html',
  styleUrls: ['./debt-quants.component.scss', '../debt.component.scss']
})
export class DebtQuantsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
