import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equity-exposure',
  templateUrl: './equity-exposure.component.html',
  styleUrls: ['./equity-exposure.component.scss', '../equity.component.scss']
})
export class EquityExposureComponent implements OnInit {

  public topAMCData = [
    { name: 'Avendus Capital', allocation: '21' },
    { name: 'HDFC Mutual Funds', allocation: '17.5' },
    { name: 'Edelweiss Group', allocation: '23' },
    { name: 'Mirae Asset', allocation: '7' },
    { name: 'White Oak', allocation: '11' },
    { name: 'Other AMCs', allocation: '9' },
    { name: 'Avendus Capital', allocation: '22' },
    { name: 'HDFC Mutual Funds', allocation: '16' },
    { name: 'Edelweiss Group', allocation: '25' },
    { name: 'Mirae Asset', allocation: '17' },
  ]

  constructor() { }

  ngOnInit() {
  }

}
