import { Component, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { DragScrollComponent } from 'ngx-drag-scroll/lib';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss']
})
export class InsightsComponent implements OnInit {

  public Highcharts = Highcharts;
  public marketClosedGraph: any;
  public topGainersGraph: any;
  public topLosersGraph: any;
  public showLeftScrollButtonAsset = false;
  public showRightScrollButtonAsset = false;
  public showLeftScrollButtonWealth = false;
  public showRightScrollButtonWealth = false;
  public showLeftScrollButtonInvest = false;
  public showRightScrollButtonInvest = false;
  public onRightClickAsset = false;
  public onRightClickWealth = false;
  public onRightClickInvest = false;


  @ViewChild('assetmanagementcard', { read: DragScrollComponent }) asset: any;
  @ViewChild('wealthmanagementcard', { read: DragScrollComponent }) wealth: any;
  @ViewChild('investmanagementcard', { read: DragScrollComponent }) invest: any;

  constructor() { }

  ngOnInit() {
    this.genrateMarketClosedGraph();
    this.genrateTopGainersGraphGraph();
    this.genrateTopLosersGraphGraph();
  }

  genrateMarketClosedGraph() {
    this.marketClosedGraph = {
      chart: {
        type: 'area',
        width: 70,
        height: 40,
        spacing: [0, 0, 0, 0],
        backgroundColor: null
      },
      yAxis: {
        plotLines: [
          {
            color: '#808080',
            width: 1,
            value: 0,
            dashStyle: 'ShortDot'
          }],
        startOnTick: false,
        min: -5,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: null
        },
        ordinal: false,
        minorTickLength: 0,
        tickLength: 0,
        crosshair: false
      },
      xAxis: {
        margin: 10,
        lineWidth: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: null
        },
        ordinal: false,
        minorTickLength: 0,
        tickLength: 0,
        crosshair: false
      },
      title: {
        useHTML: true,
        text: ''
      },
      subtitle: { text: null },
      tooltip: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      exporting: { enabled: false },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            lineWidth: 1,
          },
          fillColor: {
            linearGradient: [0, 0, 0, 0],
            stops: [
              [0, 'rgba(255, 255, 255, 0.0)'],
              [1, 'rgba(199, 239, 196, 1)']
            ]
          }
        }
      },
      series: [{
        lineWidth: 1.5,
        color: '#65A761',
        data: [15, 18, 20, 9, 22, 19, 20, 12, 6]
      }]
    }
  }

  genrateTopGainersGraphGraph() {
    this.topGainersGraph = {
      chart: {
        type: 'area',
        width: 60,
        height: 35,
        spacing: [0, 0, 0, 0],
        marginTop: -5,
        backgroundColor: null
      },
      yAxis: {
        startOnTick: false,
        min: -5,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: null
        },
        ordinal: false,
        minorTickLength: 0,
        tickLength: 0,
        crosshair: false
      },
      xAxis: {
        margin: 10,
        lineWidth: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: null
        },
        ordinal: false,
        minorTickLength: 0,
        tickLength: 0,
        crosshair: false
      },
      title: {
        useHTML: true,
        text: ''
      },
      subtitle: { text: null },
      tooltip: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      exporting: { enabled: false },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            lineWidth: 1,
          },
          fillColor: {
            linearGradient: [0, 0, 0, 0],
            stops: [
              [0, 'rgba(255, 255, 255, 0.0)'],
              [1, 'rgba(143, 212, 139, 1)']
            ]
          }
        }
      },
      series: [{
        lineWidth: 1.5,
        color: '#8FD48B',
        data: [6, 15, 18, 20, 9, 22, 19, 20, 12, 6]
      }]
    }
  }

  genrateTopLosersGraphGraph() {
    this.topLosersGraph = {
      chart: {
        type: 'area',
        width: 60,
        height: 35,
        spacing: [0, 0, 0, 0],
        marginTop: -5,
        backgroundColor: null
      },
      yAxis: {
        startOnTick: false,
        min: -5,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: null
        },
        ordinal: false,
        minorTickLength: 0,
        tickLength: 0,
        crosshair: false
      },
      xAxis: {
        margin: 10,
        lineWidth: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: null
        },
        ordinal: false,
        minorTickLength: 0,
        tickLength: 0,
        crosshair: false
      },
      title: {
        useHTML: true,
        text: ''
      },
      subtitle: { text: null },
      tooltip: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      exporting: { enabled: false },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            lineWidth: 1,
          },
          fillColor: {
            linearGradient: [0, 0, 0, 0],
            stops: [
              [0, 'rgba(255, 255, 255, 0.0)'],
              [1, 'rgba(223, 112, 74, 1)']
            ]
          }
        }
      },
      series: [{
        lineWidth: 1.5,
        color: '#DF704A',
        data: [6, 15, 18, 20, 9, 22, 19, 20, 12, 6]
      }]
    }
  }

  moveLeft(leftdata) {
    console.log(leftdata);
    switch (leftdata) {
      case 'asset':
        this.asset.moveLeft();
        break;
      case 'wealth':
        this.wealth.moveLeft();
        break;
      case 'invest':
        this.invest.moveLeft();
        break;
      default:
        break;
    }
    // this.pc.moveLeft();
  }

  moveRight(rightdata) {
    console.log(rightdata);
    switch (rightdata) {
      case 'asset':
        this.onRightClickAsset = true;
        this.asset.moveRight();
        break;
      case 'wealth':
        this.onRightClickWealth = true;
        this.wealth.moveRight();
        break;
      case 'invest':
        this.onRightClickInvest = true;
        this.invest.moveRight();
        break;
      default:
        break;
    }
    // this.pc.moveRight();
  }

  onLeftBoundAsset(evn: any) {
    if (evn) {
      this.showLeftScrollButtonAsset = true;
    } else {
      this.showLeftScrollButtonAsset = false;
    }
  }

  onRightBoundAsset(evn: any) {
    if (evn) {
      this.showRightScrollButtonAsset = true;
    } else {
      this.showRightScrollButtonAsset = false;
    }
  }

  onLeftBoundWealth(evn: any) {
    if (evn) {
      this.showLeftScrollButtonWealth = true;
    } else {
      this.showLeftScrollButtonWealth = false;
    }
  }

  onRightBoundWealth(evn: any) {
    if (evn) {
      this.showRightScrollButtonWealth = true;
    } else {
      this.showRightScrollButtonWealth = false;
    }
  }

  onLeftBoundInvest(evn: any) {
    if (evn) {
      this.showLeftScrollButtonInvest = true;
    } else {
      this.showLeftScrollButtonInvest = false;
    }
  }

  onRightBoundInvest(evn: any) {
    if (evn) {
      this.showRightScrollButtonInvest = true;
    } else {
      this.showRightScrollButtonInvest = false;
    }
  }

}
