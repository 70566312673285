import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alternate-equity-drawdowns',
  templateUrl: './alternate-equity-drawdowns.component.html',
  styleUrls: ['./alternate-equity-drawdowns.component.scss', '../alternate-equity.component.scss']
})
export class AlternateEquityDrawdownsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
