import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-debt-exposure',
  templateUrl: './debt-exposure.component.html',
  styleUrls: ['./debt-exposure.component.scss', '../debt.component.scss']
})
export class DebtExposureComponent implements OnInit {

  public Highcharts = Highcharts;
  public amcWiseExposure: any;

  public topAMCData = [
    { name: 'Avendus Capital', allocation: '21' },
    { name: 'HDFC Mutual Funds', allocation: '17.5' },
    { name: 'Edelweiss Group', allocation: '23' },
    { name: 'Mirae Asset', allocation: '7' },
    { name: 'White Oak', allocation: '11' },
    { name: 'Other AMCs', allocation: '9' },
    { name: 'Avendus Capital', allocation: '22' },
    { name: 'HDFC Mutual Funds', allocation: '16' },
    { name: 'Edelweiss Group', allocation: '25' },
    { name: 'Mirae Asset', allocation: '17' },
  ]

  constructor() { }

  ngOnInit() {
    this.genrateAmcWiseExposureGraph();
  }

  genrateAmcWiseExposureGraph() {
    this.amcWiseExposure = {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        // align: 'top',
        type: 'pie',
        spacing: [0, -10, 0, -10],
        // marginTop: -70,
        // width: 307,
        style: {
          fontFamily: 'IBMPlexSans'
        },
        // height: 400
      },
      title: {
        verticalAlign: 'middle',
        y: -38,
        // floating: true,
        useHTML: true,
        text: ''
      },
      tooltip: {
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-value">₹40.53 Cr</span><br /><span class="tooltip-name font-size-10">21% Large Cap</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          cursor: 'pointer',
          colors: [
            '#2e3472',
            '#696ea4',
            '#9195bd',
            '#adb1d1',
            '#d0d2e5'
          ],
          // enableMouseTracking: false,
          dataLabels: {
            enabled: false
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '40%'],
          showInLegend: true,
          size: '100%',
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [{
        type: 'pie',
        data: [
          { name: 'Large Cap', y: 21 },
          { name: 'Mid Cap', y: 18 },
          { name: 'Small Cap', y: 36 },
          { name: 'International Equity', y: 5 },
          { name: 'Debt & Cash', y: 12 }
        ]
      }],
      responsive: {
        rules: [
          {
            condition: {
              minWidth: 345
            },
            chartOptions: {
              plotOptions: {
                pie: { center: ['50%', '50%'] }
              }
            }
          },
          {
            condition: {
              minWidth: 271,
              maxWidth: 344
            },
            chartOptions: {
              chart: {
                height: 400
              },
              plotOptions: {
                // center: ['50%', '50%'],
              }
            }
          },
          {
            condition: {
              minWidth: 320,
              maxWidth: 343
            },
            chartOptions: {
              plotOptions: {
                pie: { center: ['50%', '50%'] }
              }
            }
          },
          {
            condition: {
              maxWidth: 270
            },
            chartOptions: {
              plotOptions: {
                pie: { center: ['50%', '40%'] }
              }
            }
          },
          {
            condition: {
              maxWidth: 230
            },
            chartOptions: {
              chart: {
                height: 350
              },
            }
          }
        ]
      }
    }
  }

}
