import { Component, OnInit, ViewChild } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll/lib';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  @ViewChild('memberaccountcard', { read: DragScrollComponent }) ma: any;

  public showLeftScrollButton = true;
  public showRightScrollButton = true;

  constructor() { }

  ngOnInit() {
  }

  moveLeft() {
    this.ma.moveLeft();
  }

  moveRight(rightdata) {
    this.ma.moveRight();
  }

  onLeftBound(evn: any) {
    if (evn) {
      this.showLeftScrollButton = true;
    } else {
      this.showLeftScrollButton = false;
    }
  }

  onRightBound(evn: any) {
    if (evn) {
      this.showRightScrollButton = true;
    } else {
      this.showRightScrollButton = false;
    }
  }

}
