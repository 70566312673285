import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-debt-drawdown',
  templateUrl: './debt-drawdown.component.html',
  styleUrls: ['./debt-drawdown.component.scss', '../alternate-debt.component.scss']
})
export class DebtDrawdownComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
