import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cash',
  templateUrl: './cash.component.html',
  styleUrls: ['./cash.component.scss']
})
export class CashComponent implements OnInit {

  public showFilter = false;
  public showFilterAnimation = false;

  constructor() { }

  ngOnInit() {
  }

  openFilter() {
    this.showFilter = true;
    this.showFilterAnimation = true;
  }

  closeFilter() {
    this.showFilterAnimation = false;
    setTimeout(() => {
      this.showFilter = false;
    }, 500);
  }

}
