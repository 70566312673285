import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-advisor-wise-summary',
  templateUrl: './advisor-wise-summary.component.html',
  styleUrls: ['./advisor-wise-summary.component.scss']
})
export class AdvisorWiseSummaryComponent implements OnInit {

  public Highcharts = Highcharts;
  public advisorWiseSummary: any;

  public advisorWiseDataCount = [];
  public advisorWiseData = [
    {
      name: 'Advisor 1', profile: 'Balanced', investmentValue: '21.00 Cr', marketValue: '29.00', weight: '63%', lossGain: '8.00', irr: '9.21%', bmirr: '8.91%', allocation: '100',
    },
    {
      name: 'Advisor 2', profile: 'Aggressive', investmentValue: '2.00 Cr', marketValue: '2.50', weight: '5%', lossGain: '0.50', irr: '10.21%', bmirr: '11.22%', allocation: '100',
    },
    {
      name: 'Advisor 1', profile: 'Balanced', investmentValue: '21.00 Cr', marketValue: '29.00', weight: '63%', lossGain: '8.00', irr: '9.21%', bmirr: '8.91%', allocation: '100',
    },
    {
      name: 'Advisor 2', profile: 'Aggressive', investmentValue: '2.00 Cr', marketValue: '2.50', weight: '5%', lossGain: '0.50', irr: '10.21%', bmirr: '11.22%', allocation: '100',
    },
    {
      name: 'Advisor 1', profile: 'Balanced', investmentValue: '21.00 Cr', marketValue: '29.00', weight: '63%', lossGain: '8.00', irr: '9.21%', bmirr: '8.91%', allocation: '100',
    },
    {
      name: 'Total', profile: '', investmentValue: '37.00 Cr', marketValue: '46.30', weight: '100%', lossGain: '9.30', irr: '35.63%', bmirr: '32.02%', allocation: '100',
    }
  ];

  constructor() { }

  ngOnInit() {
    this.genrateAdvisorWiseSummary();
  }

  genrateAdvisorWiseSummary() {
    this.advisorWiseSummary = {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        // align: 'top',
        type: 'pie',
        // spacing: [0, 0, 0, 0],
        // marginTop: -70,
        // width: 320,
        style: {
          fontFamily: 'IBMPlexSans'
        },
        height: 320
      },
      title: {
        text: ''
      },
      // title: {
      //   verticalAlign: 'middle',
      //   y: -35,
      //   // floating: true,
      //   useHTML: true,
      //   text: '<span class="pie-title-container"><span class="pie-chart-name">Investment Value</span><br /><span class="pie-chart-value">₹37.00 Cr</span></span>'
      // },
      tooltip: {
        shape: 'rect',
        shadow: false,
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-value">₹21 Cr</span><br /><span class="tooltip-name font-size-10">Advisor 1</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        },
        // positioner: function () {
        //   return { x: 200, y: 50 };
        // },
      },
      legend: {
        itemMarginBottom: 5,
        layout: 5 < 3 ? 'vertical' : 'horizontal',
        // layout: 'horizontal',
        align: 'bottom',
        verticalAlign: 'bottom',
        borderWidth: 0,
        y: -10,
        // x: 10,
        width: 300,
        itemWidth: 150,
        useHTML: true,
        labelFormatter: function () {
          return '<span class="legend-value">' + this.y + '%' + ' ' + '</span> <span class="legend-name">  ' + this.name + '</span>';
        }
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          colors: [
            '#AD9895',
            '#BDADAA',
            '#CEC1BF',
            '#DED6D5',
            '#EFEAEA'
          ],
          // enableMouseTracking: false,
          dataLabels: {
            enabled: false
          },
          center: ['50%', '30%'],
          showInLegend: true,
          // innerSize: '92%',
          size: '65%',
          borderWidth: 0,
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [{
        type: 'pie',
        // innerSize: '92%',
        data: [
          { name: 'Advisor 1', y: 63 },
          { name: 'Advisor 2', y: 5 },
          { name: 'Advisor 3', y: 9 },
          { name: 'Advisor 4', y: 15 },
          { name: 'Advisor 5', y: 8 },
          // { name: 'Alternate', y: 9 }
        ]
        // data: [
        //   { name: 'Equity', y: 15 },
        //   { name: 'Alternate Equity', y: 24 },
        //   { name: 'Debt', y: 18 },
        //   { name: 'Alternate Debt', y: 20 },
        //   { name: 'Cash & Equiv', y: 14 },
        //   { name: 'Alternate', y: 9 }
        // ]
      }],

      responsive: {
        rules: [{
          condition: {
            minWidth: 450
          },
          chartOptions: {
            legend: {
              align: 'center',
            }
          }
        },
        // {
        //   condition: {
        //     maxWidth: 320
        //   },
        //   chartOptions: {
        //     tooltip: {
        //       positioner: function () {
        //         return { x: 180, y: 50 };
        //       },
        //     }
        //   }
        // },
        {
          condition: {
            maxWidth: 275
          },
          chartOptions: {
            legend: {
              // align: 'center',
              verticalAlign: 'bottom',
              layout: 'vertical',
              itemMarginBottom: 5,
            }
          }
        }]
      }

    };

  }

}
