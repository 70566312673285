import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-sector-and-marketcap',
  templateUrl: './sector-and-marketcap.component.html',
  styleUrls: ['./sector-and-marketcap.component.scss', '../equity.component.scss']
})
export class SectorAndMarketcapComponent implements OnInit {

  public Highcharts = Highcharts;
  public sectorWiseDivision: any;
  public marketCap: any;

  constructor() { }

  ngOnInit() {
    this.genrateSectorWiseDivisionGraph();
    this.genrateMarketCapGraph();
  }

  genrateSectorWiseDivisionGraph() {
    this.sectorWiseDivision = {
      chart: {
        type: 'bar',
        spacing: [0, 0, 50, 0],
        backgroundColor: null,
        // width: 750,
        style: {
          fontFamily: 'IBMPlexSans',
        },
      },
      yAxis: {
        lineWidth: 0,
        gridLineWidth: 0,
        min: 0,
        title: {
          text: null
        },
        labels: {
          enabled: false
        },
      },
      xAxis: {
        categories: ['Information Technology', 'Pharmaceuticals', 'Financial Services', 'Consumer Durables', 'Manufacturing', 'Oil & Gas', 'Agriculture', 'Construction'],
        title: {
          text: null
        },
        tickLength: 0,
        useHTML: true,
        labels: {
          x: -24,
          align: 'left',
          reserveSpace: true,
          style: {
            color: '#171717',
            fontSize: '14px',
            fontWeight: '600'
          }
        },
        lineWidth: 0,
        gridLineWidth: 0,
      },
      title: {
        useHTML: true,
        text: ''
      },
      subtitle: { text: null },
      tooltip: { enabled: false },
      // tooltip: {
      //   valueSuffix: ' millions',
      //   shape: 'rect',
      //   shadow: false,
      //   headerFormat: null,
      //   backgroundColor: '#000',
      //   borderRadius: 6,
      //   borderColor: '#000',
      //   width: 100,
      //   useHTML: true,
      //   formatter: function (data: any) {
      //     return '<span class="tooltip-name font-size-12 padding-bottom-4-px">' + this.x + '</span><br /><span class="tooltip-name font-size-12">' + this.series.name + '</span>: <span class="tooltip-value">' + this.y + '</span>';
      //   },
      //   style: {
      //     textAlign: 'center',
      //     color: '#fff',
      //     width: 100,
      //   }
      // },
      legend: {
        useHTML: true,
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'bottom',
        x: -8,
        y: 38,
        floating: true,
        borderWidth: 0,
        enabled: true,
        symbolHeight: 14,
        symbolWidth: 14,
        symbolRadius: 2,
        itemStyle: {
          color: '#000',
          fontWeight: '400',
          lineHeight: "12px"
        },
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      plotOptions: {
        series: {
          pointWidth: 12,
          groupPadding: 0.09,
          pointPadding: 0,
          dataLabels: {
            formatter() {
              return (this.y) + "%";
            },
            style: {
              color: '#171717',
              fontSize: '12px',
              fontWeight: '400'
            }
          }
        },
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [{
        name: '% Weight',
        color: '#DD4814',
        data: [5.4, 4.8, 6.8, 6.2, 9.4, 5.0, 6.5, 5.4]
      }, {
        name: '% Nifty 50',
        color: '#FFB99F',
        data: [8.2, 5.4, 12.8, 5.2, 8.6, 4.9, 7.4, 6.3]
      }, {
        name: '% Nifty 200',
        color: '#FDD5C7',
        data: [4.8, 5.1, 10.2, 6.7, 7.6, 6.9, 6.2, 2.7]
      }],
    }
  }

  genrateMarketCapGraph() {
    this.marketCap = {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        // align: 'top',
        type: 'pie',
        spacing: [0, -10, 0, -10],
        // marginTop: -70,
        // width: 336,
        style: {
          fontFamily: 'IBMPlexSans'
        },
        height: 350
      },
      title: {
        verticalAlign: 'middle',
        y: -38,
        // floating: true,
        useHTML: true,
        text: ''
      },
      tooltip: {
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-value">₹40.53 Cr</span><br /><span class="tooltip-name font-size-10">21% Large Cap</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          cursor: 'pointer',
          colors: [
            '#DD4814',
            '#F36939',
            '#FF9A77',
            '#FFB99F',
            '#FDD5C7'
          ],
          // enableMouseTracking: false,
          dataLabels: {
            enabled: false
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '45%'],
          showInLegend: true,
          size: '100%',
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [{
        type: 'pie',
        data: [
          { name: 'Large Cap', y: 21 },
          { name: 'Mid Cap', y: 18 },
          { name: 'Small Cap', y: 36 },
          { name: 'International Equity', y: 5 },
          { name: 'Debt & Cash', y: 12 }
        ]
      }],
      responsive: {
        rules: [
          {
            condition: {
              minWidth: 345
            },
            chartOptions: {
              plotOptions: {
                pie: { center: ['50%', '50%'] }
              }
            }
          },
          {
            condition: {
              maxWidth: 270
            },
            chartOptions: {
              plotOptions: {
                pie: { center: ['50%', '40%'] }
              }
            }
          }
        ]
      }
    }
  }
}
