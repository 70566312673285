import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-fund-details',
  templateUrl: './fund-details.component.html',
  styleUrls: ['./fund-details.component.scss']
})
export class FundDetailsComponent implements OnInit {

  public Highcharts = Highcharts;
  public performanceGraph: any;
  public marketCap: any;
  public creditQuality: any;
  public portfolioAreaGraph: any;
  public portfolioBarGraph: any;

  public sectorsData = [
    { name: 'IT', allocation: '21' },
    { name: 'Pharmaceuticals', allocation: '23' },
    { name: 'Oil & Gas', allocation: '32' },
    { name: 'Banks', allocation: '11' },
    { name: 'Consumer Durables', allocation: '17.5' },
    { name: 'Banks', allocation: '24' },
    { name: 'FMCG', allocation: '13' },
    { name: 'Agriculture', allocation: '18' },
    { name: 'Manufacturing', allocation: '22' },
    { name: 'Others', allocation: '15' }
  ]

  constructor() { }

  ngOnInit() {
    this.genratePerformanceGraphSummary();
    this.genrateMarketCapGraph();
    this.genrateCreditQualityGraph();
    this.genratePortfolioBarGraphSummary();
    this.genratePortfolioAreaGraphSummary();
  }

  genratePerformanceGraphSummary() {
    this.performanceGraph = {
      chart: {
        type: 'column',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        spacing: [60, 0, 0, -45],
        style: {
          fontFamily: 'IBMPlexSans'
        },
        // width: 750,
        height: 300
      },
      title: {
        text: ''
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        lineWidth: 0,
        // type: 'year',
        tickLength: 0,
        categories: [2018, 2019, 2020, 2021],
        style: {
          color: '#757575',
          fontSize: '10px'
        },
      },
      yAxis: {
        tickAmount: 10,
        tickLength: 0,
        // plotLines: [
        //   {
        //     color: '#c8c8c8',
        //     width: 1,
        //     value: 0
        //   }, {
        //     color: '#c8c8c8',
        //     width: 1,
        //     value: 4
        //   }, {
        //     color: '#c8c8c8',
        //     width: 1,
        //     value: 8
        //   }, {
        //     color: '#c8c8c8',
        //     width: 1,
        //     value: 12
        //   }, {
        //     color: '#c8c8c8',
        //     width: 1,
        //     value: 16
        //   }, {
        //     color: '#c8c8c8',
        //     width: 1,
        //     value: 20
        //   }, {
        //     color: '#c8c8c8',
        //     width: 1,
        //     value: 24
        //   }, {
        //     color: '#c8c8c8',
        //     width: 1,
        //     value: 28
        //   }],
        gridLineWidth: 1,
        title: {
          align: 'low',
          text: 'in ₹ Cr',
          rotation: 0,
          y: 20,
          x: 10,
          style: {
            // fontSize: '12px',
            color: '#757575'
          }
        },
        labels: {
          formatter() {
            if (this.value == 0) {
              return "";
            }
            // else if (this.value > 1200) {
            //   return "";
            // }
            return (this.value);
          },
          x: 4,
          y: -5,
          align: 'left',
          style: {
            color: '#757575',
            fontSize: '12px'
          }
        }

      },
      legend: {
        useHTML: true,
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'top',
        // x: 0,
        y: -55,
        floating: true,
        borderWidth: 0,
        enabled: true,
        symbolHeight: 14,
        symbolWidth: 14,
        symbolRadius: 2,
        itemStyle: {
          color: '#000',
          fontWeight: '400',
          lineHeight: "12px"
        },
      },
      plotOptions: {
        series: {
          groupPadding: 0.25,
          pointPadding: 0,
          pointWidth: 40,
          borderWidth: 0,
          dataLabels: {
            enabled: false
          }
        }
      },
      tooltip: {
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-value font-size-16">' + this.y + '%</span><br /><span class="tooltip-name font-size-10">Jan 2021</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      series: [{
        name: 'Revenue',
        color: '#4891FF',
        data: [4500, 7000, 14000, 11000]
      }, {
        name: 'Earnings',
        color: '#000000',
        data: [1800, 1000, 1500, 3000]
      }],

      responsive: {
        rules: [{
          condition: {
            maxWidth: 660
          },
          chartOptions: {
            plotOptions: {
              series: {
                pointWidth: 20,
              }
            }
          }
        }, {
          condition: {
            maxWidth: 425
          },
          chartOptions: {
            legend: {
              useHTML: true,
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
              y: 40
            },
          }
        },
        {
          condition: {
            maxWidth: 400
          },
          chartOptions: {
            plotOptions: {
              series: {
                pointWidth: 10,
              }
            }
          }
        }]
      }
    }
  }

  genrateMarketCapGraph() {
    this.marketCap = {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        // align: 'top',
        type: 'pie',
        spacing: [0, 0, 0, 0],
        // width: 336,

        style: {
          fontFamily: 'IBMPlexSans'
        },
        height: 300
      },
      title: {
        verticalAlign: 'middle',
        y: -38,
        // floating: true,
        useHTML: true,
        text: ''
      },
      tooltip: {
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-value">₹40.53 Cr</span><br /><span class="tooltip-name font-size-10">21% Large Cap</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      legend: {
        itemMarginBottom: 5,
        layout: 5 < 3 ? 'vertical' : 'horizontal',
        // layout: 'horizontal',
        align: 'bottom',
        verticalAlign: 'bottom',
        borderWidth: 0,
        y: -10,
        // x: 10,
        width: 330,
        itemWidth: 165,
        useHTML: true,
        labelFormatter: function () {
          return '<span class="large-sub-text-header color-black regular">' + this.y + '%' + ' ' + '</span> <span class="large-sub-text-header color-black semibold">  ' + this.name + '</span><br/>';
        }
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          cursor: 'pointer',
          colors: [
            '#DD4814',
            '#F36939',
            '#FF9A77',
            '#FFB99F',
            '#FDD5C7'
          ],
          // enableMouseTracking: false,
          dataLabels: {
            enabled: false
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '55%'],
          showInLegend: true,
          size: '100%',
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [{
        type: 'pie',
        data: [
          { name: 'Large Cap', y: 21 },
          { name: 'Mid Cap', y: 18 },
          { name: 'Small Cap', y: 36 },
          { name: 'International Equity', y: 5 },
          { name: 'Debt & Cash', y: 12 }
        ]
      }],

      responsive: {
        rules: [{
          condition: {
            minWidth: 450
          },
          chartOptions: {
            chart: {
              height: 300
            },
            plotOptions: {
              pie: {
                size: '150%',

                center: ['50%', '75%'],
              }
            },
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
            }
          }
        },
        {
          condition: {
            maxWidth: 275
          },
          chartOptions: {
            legend: {
              // align: 'center',
              verticalAlign: 'bottom',
              layout: 'vertical',
              itemMarginBottom: 5,
            }
          }
        }]
      }
    }
  }

  genrateCreditQualityGraph() {
    this.creditQuality = {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        // align: 'top',
        type: 'pie',
        spacing: [0, 0, 0, 0],
        // width: 336,

        style: {
          fontFamily: 'IBMPlexSans'
        },
        height: 300
      },
      title: {
        verticalAlign: 'middle',
        y: -38,
        // floating: true,
        useHTML: true,
        text: ''
      },
      tooltip: {
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-value">₹40.53 Cr</span><br /><span class="tooltip-name font-size-10">21% Large Cap</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      legend: {
        itemMarginBottom: 5,
        layout: 5 < 3 ? 'vertical' : 'horizontal',
        // layout: 'horizontal',
        align: 'bottom',
        verticalAlign: 'bottom',
        borderWidth: 0,
        y: -10,
        // x: 10,
        width: 330,
        itemWidth: 165,
        useHTML: true,
        labelFormatter: function () {
          return '<span class="large-sub-text-header color-black regular">' + this.y + '%' + ' ' + '</span> <span class="large-sub-text-header color-black semibold">  ' + this.name + '</span><br/>';
        }
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          cursor: 'pointer',
          colors: [
            '#2e3472',
            '#696ea4',
            '#9195bd',
            '#adb1d1',
            '#d0d2e5'
          ],
          // enableMouseTracking: false,
          dataLabels: {
            enabled: false
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '55%'],
          showInLegend: true,
          size: '100%',
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [{
        type: 'pie',
        data: [
          { name: 'AAA(A+)', y: 45 },
          { name: 'AA & Eq', y: 24 },
          { name: 'A & Eq', y: 18 },
          { name: 'BB & Eq', y: 10 },
          { name: 'Unlisted', y: 10 }
        ]
      }],

      responsive: {
        rules: [{
          condition: {
            minWidth: 450
          },
          chartOptions: {
            chart: {
              height: 300
            },
            plotOptions: {
              pie: {
                size: '150%',

                center: ['50%', '75%'],
              }
            },
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
            }
          }
        },
        {
          condition: {
            maxWidth: 275
          },
          chartOptions: {
            legend: {
              // align: 'center',
              verticalAlign: 'bottom',
              layout: 'vertical',
              itemMarginBottom: 5,
            }
          }
        }]
      }
    }
  }

  genratePortfolioBarGraphSummary() {
    this.portfolioBarGraph = {
      chart: {
        type: 'bar',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        spacing: [40, 0, 0, 40],
        style: {
          fontFamily: 'IBMPlexSans'
        },
        // width: 740
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['YTD', 'SI', '3Y', '1Y', '6M', '1M'],
        title: {
          text: null
        },
        tickLength: 0,
        lineColor: '#C8C8C8',
        useHTML: true,
        labels: {
          // step: 2,
          // lineWidth: 0,
          x: -40,

          align: 'left',
          color: '#C8C8C8'
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
        labels: {
          overflow: 'justify',
          // formatter() {
          //   if (this.value !== 0) {
          //     return this.value + '%';
          //   }
          // }
        },
        gridLineWidth: 0,
        lineColor: '#C8C8C8',
        lineWidth: 1
      },
      tooltip: {
        valueSuffix: ' millions',
        shape: 'rect',
        shadow: false,
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-name font-size-12 padding-bottom-4-px">' + this.x + '</span><br /><span class="tooltip-name font-size-12">' + this.series.name + '</span>: <span class="tooltip-value">' + this.y + '</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'top',
        x: -45,
        y: -45,
        floating: true,
        borderWidth: 0,
        enabled: true,
        symbolHeight: 14,
        symbolWidth: 14,
        symbolRadius: 2,

      },
      series: [{
        color: '#4891FF',
        name: 'IRR',
        data: [12.6, 32.8, 18.7, 15, 48.9, 28.6]
      },
      {
        color: '#000000',
        name: 'BMIRR',
        data: [20.3, 52.9, 9.8, 20, 35, 40]
      }
      ]
    }
  }

  genratePortfolioAreaGraphSummary() {
    this.portfolioAreaGraph = {
      chart: {
        type: 'area',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        spacing: [60, 0, 0, 0],
        style: {
          fontFamily: 'IBMPlexSans'
        },
        // width: 350,
        height: 270
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      title: {
        text: ''
      },
      xAxis: {
        useHTML: true,
        categories: ['Dec 2020', 'Jan 2021', 'Feb 2021', 'Mar 2021', 'Apr 2021', 'May 2021', 'Jun 2021', 'Jul 2021', 'Aug 2021', 'Sep 2021', 'Oct 2021', 'Nov 2021'],
        lineWidth: 0,
        tickLength: 0,
        type: 'category',
        rotate: 0,
        labels: {
          rotation: 0,
          step: 1,
          style: {
            color: '#757575',
            fontSize: '10px',
            width: '30px',
            textTransform: 'uppercase'
          },
        },
        crosshair: {
          width: 3,
          color: '#000',
          // zIndex: 3
        }
      },
      yAxis: {
        title: {
          text: ''
        },
        tickLength: 0,
        gridLineWidth: 0.5,
        gridLineColor: '#C8C8C8',
        labels: {
          // formatter() {
          //   if (this.value !== 0) {
          //     return this.value;
          //   } else {
          //     return ''
          //   }
          // },
          x: 0,
          y: -8,
          align: 'left',
          style: {
            color: '#757575',
            fontSize: '12px',
            width: '10px',
          },
          step: 1
        }
      },
      tooltip: {
        shape: 'rect',
        shadow: false,
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        // width: 100,
        useHTML: true,
        formatter() {
          return '<span class="tooltip-value font-size-16">₹962.35</span>' +
            '<br /><span class="tooltip-name font-size-10 padding-top-1-px">25 May 2021</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      plotOptions: {
        series: {
          marker: {
            y: -30,
            enabled: false,
            fillColor: '#fff',
            lineWidth: 2,
            lineColor: '#000',
            borderColor: '#000',
            color: 'rgba(255, 255, 255, 0)',
            radius: 7
          },
          fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
              [0, 'rgba(72, 145, 255, 0.5)'],
              [1, 'rgba(72, 145, 255, 0)'],
            ]
          },
          // pointPlacement: 0.5,
        }
      },
      legend: {
        enabled: false,
      },
      series: [{
        data: [
          // { y: 0, color: 'rgba(0 ,0 ,0 ,0)' },
          600, 750, 795, 930, 1030, 1400, 1203, 1600, 750, 795, 930, 830
        ]
      },
      ]
    }
  }

}
