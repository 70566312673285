import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  public individualReportsData = [
    { name: 'Holdings Report', details: 'This report details out the of how different allocations in asset classes are performing' },
    { name: 'Portfolio Analytics Report', details: 'The analytical report states out the of overall portfolio performance' },
    { name: 'Cashflow Report', details: 'The report mentions changes in accounts & income which affect cash balance ' },
    { name: 'Transactions Report', details: 'The detailed billing information of each transaction' },
    { name: 'Corporate Action Report', details: 'The company events which are materially impacting the stakeholders' },
    { name: 'Capital Gains Report', details: 'The report explains the profits earned on the sale of an asset' }
  ]

  public previousReportDetails = [
    {
      year: '2019-2020',
      date: '1 Apr 2019 – 31 Mar 2020',
      reports: [
        {
          reportname: 'Detailed Report',
          reportdate: '5 Jul 2020 • 5.98 Kb'
        },
        {
          reportname: 'Capital Gains Report',
          reportdate: '5 Jul 2020 • 5.98 Kb'
        }
      ]
    },
    {
      year: '2018-2019',
      date: '1 Apr 2018 – 31 Mar 2019',
      reports: [
        {
          reportname: 'Detailed Report',
          reportdate: '5 Jul 2019 • 5.98 Kb'
        },
        {
          reportname: 'Capital Gains Report',
          reportdate: '5 Jul 2019 • 5.98 Kb'
        }
      ]
    },
  ]

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  constructor() { }

  ngOnInit() {
    this.dropdownList = [
      { 'itemName': 'Krishnamurthy Family' },
      { 'itemName': 'Mr. Krisan Krishnamurthy' },
      { 'itemName': 'Mrs. Shreya Krishnamurthy' },
      { 'itemName': 'Mrs. Ray Krishnamurthy' },
      { 'itemName': 'Mrs. Riya Krishnamurthy' },
      { 'itemName': 'Mrs. Sam Krishnamurthy' },
      { 'itemName': 'Mrs. Shyam Krishnamurthy' }
    ];
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Mr. Krisan Krishnamurthy',
      selectAllText: 'Mr. Krisan Krishnamurthy',
      unSelectAllText: 'Mr. Krisan Krishnamurthy',
      enableSearchFilter: true,
      classes: 'multiselect-section'
    };
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }

}
