import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rm-meeting-note',
  templateUrl: './meeting-note.component.html',
  styleUrls: ['./meeting-note.component.scss', '../rm-meeting.component.scss']
})
export class RMMeetingNoteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
// , '../equity.component.scss'
