import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-credit-quality-and-maturity',
  templateUrl: './credit-quality-and-maturity.component.html',
  styleUrls: ['./credit-quality-and-maturity.component.scss', '../debt.component.scss']
})
export class CreditQualityAndMaturityComponent implements OnInit {

  public Highcharts = Highcharts;
  public sectorWiseDivision: any;
  public marketCap: any;

  constructor() { }

  ngOnInit() {
    this.genrateSectorWiseDivisionGraph();
    this.genrateMarketCapGraph();
  }

  genrateSectorWiseDivisionGraph() {
    this.sectorWiseDivision = {
      chart: {
        type: 'bar',
        spacing: [0, 0, 50, 20],
        backgroundColor: null,
        // width: 750,
        height: 180,
        style: {
          fontFamily: 'IBMPlexSans',
        },
      },
      yAxis: {
        lineWidth: 0,
        gridLineWidth: 0,
        min: 0,
        title: {
          text: null
        },
        labels: {
          enabled: false
        },
      },
      xAxis: {
        categories: ['> 5 Years', '3-5 Years', '1-3 Years', '3 Months - 1 Year', '1-3 Months'],
        title: {
          text: null
        },
        tickLength: 0,
        useHTML: true,
        labels: {
          x: -34,
          align: 'left',
          reserveSpace: true,
          style: {
            color: '#171717',
            fontSize: '14px',
            fontWeight: '600'
          }
        },
        lineWidth: 0,
        gridLineWidth: 0,
      },
      title: {
        useHTML: true,
        text: ''
      },
      subtitle: { text: null },
      tooltip: { enabled: false },
      legend: {
        enabled: false
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      plotOptions: {
        series: {
          pointWidth: 12,
          groupPadding: 0.09,
          pointPadding: 0,
          dataLabels: {
            formatter() {
              return (this.y) + "%";
            },
            style: {
              color: '#171717',
              fontSize: '12px',
              fontWeight: '400'
            }
          }
        },
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [{
        name: '% Weight',
        color: '#556594',
        data: [5.4, 4.8, 6.8, 6.2, 9.4]
      }]
    }
  }

  genrateMarketCapGraph() {
    this.marketCap = {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        // align: 'top',
        type: 'pie',
        spacing: [0, 0, 0, 0],
        // marginTop: -70,
        // width: 336,
        style: {
          fontFamily: 'IBMPlexSans'
        },
        height: 220
      },
      title: {
        align: 'right',
        x: -5,
        verticalAlign: 'bottom',
        y: -2,
        // floating: true,
        // y: -70,
        // floating: true,
        useHTML: true,
        text: '<span class="color-grey1 font-size-10 line-height-11-px">COVERAGE <span class="semibold">95%</span></span>'
      },
      tooltip: {
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-value">₹40.53 Cr</span><br /><span class="tooltip-name font-size-10">21% Large Cap</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      legend: {
        symbolHeight: 10,
        symbolWeight: 10,
        symbolRadius: 5,
        itemMarginBottom: 3,
        layout: 5 < 3 ? 'vertical' : 'horizontal',
        // layout: 'horizontal',
        align: 'bottom',
        verticalAlign: 'bottom',
        borderWidth: 0,
        y: 3,
        // x: 10,
        width: 330,
        itemWidth: 165,
        useHTML: true,
        labelFormatter: function () {
          return '<span class="large-sub-text-header color-black regular font-size-12">' + this.y + '%' + ' ' + '</span> <span class="large-sub-text-header color-black regular font-size-12">  ' + this.name + '</span><br/>';
        }
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          cursor: 'pointer',
          colors: [
            '#2e3472',
            '#696ea4',
            '#9195bd',
            '#adb1d1',
            '#d0d2e5'
          ],
          // enableMouseTracking: false,
          dataLabels: {
            enabled: false
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '66%'],
          showInLegend: true,
          size: '130%',
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [{
        type: 'pie',
        data: [
          { name: 'AAA (A+)', y: 25 },
          { name: 'A & Eq', y: 22 },
          { name: 'A & Eq', y: 18 },
          { name: 'BB & Eq', y: 10 },
          { name: 'Unlisted', y: 14 }
        ]
      }],

      responsive: {
        rules: [{
          condition: {
            minWidth: 450
          },
          chartOptions: {
            chart: {
              height: 300
            },
            plotOptions: {
              pie: {
                size: '150%',

                center: ['50%', '75%'],
              }
            },
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
            }
          }
        },
        {
          condition: {
            maxWidth: 275
          },
          chartOptions: {
            legend: {
              // align: 'center',
              verticalAlign: 'bottom',
              layout: 'vertical',
              itemMarginBottom: 5,
            }
          }
        }]
      }
    }
  }

}
