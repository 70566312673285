import { Component, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { DragScrollComponent } from 'ngx-drag-scroll';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public Highcharts = Highcharts;
  public allocationAssetGraph: any;
  public engagementGraph: any;
  public isHeddaway = false;
  public moreClicked = false
  public aummovementGraph: any;
  public movementGraph: any;
  public amcIssuerGraph: any;
  public liquidityScheduleGraph: any;
  public amcIssuerData = [
    {
      name: "",
      y: 0,
      color: 'rgba(255, 255, 255, 0)',
      pointWidth: 20,
      assettype: null
    },
    {
      name: "Avendus",
      y: 10,
      color: '#4891FF',
      assettype: 'equity'
    },
    {
      name: "ICICI",
      y: 7,
      color: '#ABB7C8',
      assettype: 'debt'
    },
    {
      name: "NIPPON",
      y: 5,
      color: '#ABB7C8',
      assettype: 'debt'
    },
    {
      name: "IDFC",
      y: 5,
      color: '#000000',
      assettype: 'alternate'
    },
    {
      name: "MIRAE",
      y: 3.8,
      color: '#4891FF',
      assettype: 'equity'
    },
    {
      name: "C&E",
      y: 3.5,
      color: '#000000',
      assettype: 'alternate'
    },
    {
      name: "Edelwiss",
      y: 2.2,
      color: '#ABB7C8',
      assettype: 'debt'
    },
    {
      name: "Motilal",
      y: 2.2,
      color: '#4891FF',
      assettype: 'equity'
    },
    {
      name: "hdfc",
      y: 1.9,
      color: '#ABB7C8',
      assettype: 'debt'
    },
    {
      name: "",
      y: 1,
      color: 'rgba(255, 255, 255, 0)',
      pointWidth: 40,
      assettype: null
    }
  ];
  public amcData = this.amcIssuerData;
  public amcDataDuplicate = this.amcIssuerData;
  public equitySelect = true;
  public debtSelect = true;
  public alternateSelect = true;
  public showLeftScrollButton = true;
  public showRightScrollButton = true;
  public showLeftScrollButtonInvestment = true;
  public showRightScrollButtonInvestment = true;
  public aumChartSelected = true;
  public returnChartSelected = false;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  @ViewChild('portfoliocard', { read: DragScrollComponent }) pc: any;
  @ViewChild('investmentdetails', { read: DragScrollComponent }) ind: any;

  constructor() { }

  ngOnInit() {
    this.genrateAllocationSummary();
    this.genrateEngagementGraphSummary();
    this.genrateAUMMovementGraphSummary();
    this.genrateMovementGraphSummary();
    this.genrateamcIssuerGraphSummary();
    this.genrateLiquidityScheduleGraphSummary();

    this.dropdownList = [
      { 'itemName': 'All Asset Class' },
      { 'itemName': 'All Asset Class' },
      { 'itemName': 'All Asset Class' }
    ];
    this.dropdownSettings = {
      singleSelection: true,
      text: 'All Asset Class',
      selectAllText: 'All Asset Class',
      unSelectAllText: 'All Asset Class',
      enableSearchFilter: true,
      classes: 'multiselect-section'
    };
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }

  genrateAllocationSummary() {
    this.allocationAssetGraph = {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        // align: 'top',
        type: 'pie',
        // spacing: [0, 0, 0, 0],
        // spacingBottom: 60,
        // marginTop: -70,
        // width: 350,
        height: 434,
        style: {
          fontFamily: 'IBMPlexSans'
        },

        events: {
          load: function () {
            const titleHeight = this.title.getBBox().height;
            const titleWidth = this.title.getBBox().width;
            this.title.attr({
              y: this.plotHeight / 3,
              x: this.plotWidth / 2,
            });
          },
          redraw: function () {
            const titleHeight = this.title.getBBox().height;
            const titleWidth = this.title.getBBox().width;
            this.title.attr({
              y: this.plotHeight / 3,
              x: this.plotWidth / 2,
            });
          },
        },
        // height: 350
      },
      title: {
        verticalAlign: 'middle',
        align: 'center',
        y: -70,
        // floating: true,
        useHTML: true,
        text: '<span class="pie-title-container"><span class="pie-chart-name">Portfolio Value</span><br /><span class="pie-chart-value">₹540.45 Cr</span></span>'
      },
      tooltip: {
        shape: 'rect',
        shadow: false,
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-value">₹81.06 Cr</span><br /><span class="tooltip-name font-size-12">15% Equity</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      legend: {
        itemMarginBottom: 3,
        // layout: 5 < 3 ? 'vertical' : 'horizontal',
        layout: 'horizontal',
        align: 'bottom',
        verticalAlign: 'bottom',
        borderWidth: 0,
        y: 17,
        x: -20,
        width: 300,
        // itemWidth: 300,
        useHTML: true,
        labelFormatter: function () {
          return '<span class="legend-value">' + this.y + '%' + ' ' + '</span> <span class="legend-name">  ' + this.name + '</span><br/>';
        }
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          colors: [
            '#FB7648',
            '#EAC2D0',
            '#556594',
            '#A2CFEE',
            '#8BCBA7',
            '#DFD7D7'
          ],
          // enableMouseTracking: false,
          dataLabels: {
            enabled: false
          },
          center: ['50%', '35%'],
          showInLegend: true,
          innerSize: '92%',
          size: '80%',
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [{
        type: 'pie',
        innerSize: '92%',
        data: [
          { name: 'Equity', y: 15 },
          { name: 'Alternate Equity', y: 24 },
          { name: 'Debt', y: 18 },
          { name: 'Alternate Debt', y: 20 },
          { name: 'Cash & Equiv', y: 14 },
          { name: 'Alternate', y: 9 }
        ]
      }],
    }
  }

  genrateEngagementGraphSummary() {
    this.engagementGraph = {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        // align: 'top',
        type: 'pie',
        spacing: [0, 0, 0, 0],
        // marginTop: -70,
        width: 350,
        height: 450,
        style: {
          fontFamily: 'IBMPlexSans'
        }
        // height: 350
      },
      title: {
        verticalAlign: 'middle',
        y: -35,
        // floating: true,
        useHTML: true,
        text: '<span class="pie-title-container"><span class="pie-chart-name">Investment Value</span><br /><span class="pie-chart-value">₹37.00 Cr</span></span>'
      },
      tooltip: {
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-value">₹81.06 Cr</span><br /><span class="tooltip-name font-size-12">15% Equity</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      legend: {
        itemMarginBottom: 5,
        // layout: 5 < 3 ? 'vertical' : 'horizontal',
        layout: 'horizontal',
        align: 'bottom',
        verticalAlign: 'bottom',
        borderWidth: 0,
        y: 0,
        // x: 10,
        // width: 300,
        // itemWidth: 300,
        useHTML: true,
        labelFormatter: function () {
          return '<span class="legend-value">' + this.y + '%' + ' ' + '</span> <span class="legend-name">  ' + this.name + '</span><br/>';
        }
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          colors: [
            '#95CFD0',
            '#5D9AC2',
            '#EFA68D',
            '#FB7648',
            '#1D3557'
          ],
          // enableMouseTracking: false,
          dataLabels: {
            enabled: false
          },
          center: ['50%', '35%'],
          showInLegend: true,
          innerSize: '92%',
          size: '80%',
          height: '80%'
        }
      },
      series: [{
        type: 'pie',
        innerSize: '92%',
        data: [
          { name: 'NDPMS', y: 39 },
          { name: 'Executionary', y: 11 },
          { name: 'Execuationary POA', y: 24 },
          { name: 'Heldaway', y: 8 },
          { name: 'Advisory', y: 18 }
        ]
      }],
    }

  }

  genrateAUMMovementGraphSummary() {
    this.aummovementGraph = {
      chart: {
        type: 'area',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        spacing: [50, 0, 0, 0],
        style: {
          fontFamily: 'IBMPlexSans'
        },
        // width: 350
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      title: {
        text: ''
      },
      xAxis: {
        useHTML: true,
        categories: ['Dec 2020', 'Jan 2021', 'Feb 2021', 'Mar 2021', 'Apr 2021', 'May 2021', 'Jun 2021'],
        lineWidth: 0,
        tickLength: 0,
        type: 'category',
        rotate: 0,
        labels: {
          rotation: 0,
          step: 1,
          style: {
            color: '#757575',
            fontSize: '10px',
            width: '30px',
            textTransform: 'uppercase'
          },
        },
        crosshair: {
          width: 3,
          color: '#000',
          // zIndex: 3
        }
      },
      yAxis: {
        title: {
          text: ''
        },
        tickLength: 0,
        gridLineWidth: 0.5,
        gridLineColor: '#C8C8C8',
        labels: {
          formatter() {
            if (this.value !== 0) {
              return this.value;
            } else {
              return ''
            }
          },
          x: 0,
          y: 12,
          align: 'left',
          style: {
            color: '#757575',
            fontSize: '12px',
            width: '10px',
          },
          step: 1
        }
      },
      tooltip: {
        shape: 'rect',
        shadow: false,
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        // width: 100,
        useHTML: true,
        formatter() {
          return '<span class="tooltip-value font-size-16"> + ₹36.24 Cr</span>' +
            '<br /><span class="tooltip-name font-size-10 padding-top-1-px">IRR  <span class="semibold color-white">32.6%</span></span>' +
            '<span class="tooltip-name font-size-10 padding-left-6-px padding-top-1-px">BMIRR  <span class="semibold color-white">26.3%</span></span>' +
            '<br /><span class="tooltip-name font-size-10 padding-top-1-px">22 MAR 2021</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          // width: 100,
        }
      },
      plotOptions: {
        series: {
          marker: {
            y: -30,
            enabled: false,
            fillColor: '#fff',
            lineWidth: 2,
            lineColor: '#000',
            borderColor: '#000',
            color: 'rgba(255, 255, 255, 0)',
            radius: 7
          },
          fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
              [0, 'rgba(72, 145, 255, 0.5)'],
              [1, 'rgba(72, 145, 255, 0)'],
            ]
          },
          // pointPlacement: 0.5,
        }
      },
      legend: {
        enabled: false,
      },
      series: [{
        data: [
          // { y: 0, color: 'rgba(0 ,0 ,0 ,0)' },
          600, 750, 795, 930, 830, 1000, 1203
        ]
      },
      ]
    }
  }

  genrateMovementGraphSummary() {
    this.movementGraph = {
      chart: {
        type: 'bar',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        spacing: [40, 0, 40, 24],
        style: {
          fontFamily: 'IBMPlexSans'
        },
        // width: 350
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['YTD', 'SI', '3Y', '1Y', '6M', '1M'],
        title: {
          text: null
        },
        tickLength: 0,
        lineColor: '#C8C8C8',
        useHTML: true,
        labels: {
          // step: 2,
          // lineWidth: 0,
          x: -24,

          align: 'left',
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
        tickLength: 0,
        labels: {
          overflow: 'justify',
          formatter() {
            if (this.value !== 0) {
              return this.value + '%';
            }
          }
        },
        gridLineWidth: 0,
        lineColor: '#C8C8C8',
        lineWidth: 1
      },
      tooltip: {
        valueSuffix: ' millions',
        shape: 'rect',
        shadow: false,
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-name font-size-12 padding-bottom-4-px">' + this.x + '</span><br /><span class="tooltip-name font-size-12">' + this.series.name + '</span>: <span class="tooltip-value">' + this.y + '</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'bottom',
        x: -30,
        y: 40,
        floating: true,
        borderWidth: 0,
        enabled: true,
        symbolHeight: 12,
        symbolWidth: 12,
        symbolRadius: 2,

      },
      series: [{
        color: '#4891FF',
        name: 'IRR',
        data: [12.6, 32.8, 18.7, 15, 48.9, 28.6]
      },
      {
        color: '#000',
        name: 'BMIRR',
        data: [20.3, 52.9, 9.8, 20, 35, 40]
      }
      ]
    }
  }

  genrateamcIssuerGraphSummary() {
    this.amcIssuerGraph = {
      chart: {
        type: 'column',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        spacing: [0, 0, 0, -40],
        style: {
          fontFamily: 'IBMPlexSans'
        },
        // width: 750,
        height: 180
      },
      title: {
        text: ''
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        type: 'category',
        tickLength: 0,
        style: {
          color: '#757575',
          fontSize: '10px',
          textTransform: 'Uppercase'
        }
      },
      yAxis: {
        title: {
          align: 'low',
          offset: 0,
          text: 'in ₹ Cr',
          rotation: 0,
          y: 18,
          x: 5,
          style: {
            // fontSize: '12px',
            color: '#757575'
          }
        },
        min: 0,
        max: 12,
        tickInterval: 2,
        labels: {
          x: 5,
          y: -5,
          align: 'left',
          style: {
            color: '#757575',
            fontSize: '12px'
          },
          formatter() {
            if (this.value !== 0) {
              return this.value;
            } else {
              return ''
            }
          },
        }

      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          pointWidth: 40,
          borderWidth: 0,
          dataLabels: {
            enabled: false
          }
        }
      },
      tooltip: {
        shape: 'rect',
        shadow: false,
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-value font-size-16">' + this.series.name + '</span><br /><span class="tooltip-name font-size-10">' + this.y + '%</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },

      series: [
        {
          // name: "Browsers",
          colorByPoint: true,
          data: this.amcData
        }
      ],

      responsive: {
        rules: [{
          condition: {
            maxWidth: 530
          },
          chartOptions: {
            series: {
              pointWidth: 20,
            }
          }
        },
        {
          condition: {
            maxWidth: 320
          },
          chartOptions: {
            series: {
              pointWidth: 10,
            }
          }
        },
        {
          condition: {
            maxWidth: 250
          },
          chartOptions: {
            series: {
              pointWidth: 8,
            }
          }
        }]
      }
    }
  }

  genrateLiquidityScheduleGraphSummary() {
    this.liquidityScheduleGraph = {
      chart: {
        type: 'column',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        backgroundColor: '#fff',
        spacing: [0, 20, 0, 20],
        style: {
          fontFamily: 'IBMPlexSans'
        },
        // width: 378,
        height: 250
      },
      title: {
        text: ''
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        type: 'category',
        tickLength: 0,
        style: {
          color: '#757575',
          fontSize: '10px'
        },
        lineColor: '#C8C8C8',
        lineWidth: 0.5,
      },
      yAxis: {
        title: {
          text: ''
        },
        min: 0,
        tickInterval: 2,
        tickLength: 0,
        gridLineColor: '#C8C8C8',
        gridLineWidth: 0.5,
        labels: {
          x: 0,
          y: -10,
          align: 'left',
          style: {
            color: '#757575',
            fontSize: '12px'
          },
          // overflow: 'justify',
          formatter() {
            if (this.value == 0) {
              return;
            }
            else if (this.value !== 0) {
              return this.value + ' Cr';
            }
          }
        }

      },
      tooltip: {
        shape: 'rect',
        shadow: false,
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-name font-size-12">Value: </span><span class="tooltip-value">' + this.y + '</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          maxPointWidth: 40,
          dataLabels: {
            enabled: false
          },
          // pointPlacement: 'on',
          // groupPadding: -1,
          // pointPadding: 0.2,
        }
      },

      series: [
        {
          color: '#4891FF',
          data: [
            {
              name: '', y: 0,
              pointWidth: 0,
            },
            { name: 'AVAILABLE', y: 31 },
            { name: '2021', y: 22 },
            { name: '2002', y: 13 },
            { name: '2003', y: 19 },
          ]
        }
      ],

      responsive: {
        rules: [{
          condition: {
            maxWidth: 350
          },
          chartOptions: {
            plotOptions: {
              series: {
                pointWidth: 20,
              }
            }
          }
        }]
      }
    }
  }

  equitySelected() {
    this.equitySelect = !this.equitySelect;
    if (this.equitySelect == false) {
      this.amcData = this.amcData.filter(item => item.assettype !== 'equity')
    } else {
      this.amcData = this.amcDataDuplicate;
    }
    this.genrateamcIssuerGraphSummary();
  }

  debtSelected() {
    this.debtSelect = !this.debtSelect;
    if (this.debtSelect == false) {
      this.amcData = this.amcData.filter(item => item.assettype !== 'debt')
    } else {
      this.amcData = this.amcDataDuplicate;
    }
    this.genrateamcIssuerGraphSummary();
  }

  alternateSelected() {
    this.alternateSelect = !this.alternateSelect;
    if (this.alternateSelect == false) {
      this.amcData = this.amcData.filter(item => item.assettype !== 'alternate')
    } else {
      this.amcData = this.amcDataDuplicate;
    }
    this.genrateamcIssuerGraphSummary();
  }

  moveLeft(leftdata) {
    console.log(leftdata);
    switch (leftdata) {
      case 'pc':
        this.pc.moveLeft();
        break;
      case 'ind':
        this.ind.moveLeft();
        break;
      default:
        break;
    }
    // this.pc.moveLeft();
  }

  moveRight(rightdata) {
    console.log(rightdata);
    switch (rightdata) {
      case 'pc':
        this.pc.moveRight();
        break;
      case 'ind':
        this.ind.moveRight();
        break;
      default:
        break;
    }
    // this.pc.moveRight();
  }

  // investmentMoveLeft() {
  //   this.ind.investmentMoveLeft();
  // }

  // investmentMoveRight() {
  //   this.ind.investmentMoveLeft();
  // }


  onLeftBound(evn: any) {
    if (evn) {
      this.showLeftScrollButton = true;
    } else {
      this.showLeftScrollButton = false;
    }
  }

  onRightBound(evn: any) {
    if (evn) {
      this.showRightScrollButton = true;
    } else {
      this.showRightScrollButton = false;
    }
  }

  onLeftBoundInvestment(evn: any) {
    if (evn) {
      this.showLeftScrollButtonInvestment = true;
    } else {
      this.showLeftScrollButtonInvestment = false;
    }
  }

  onRightBoundInvestment(evn: any) {
    if (evn) {
      this.showRightScrollButtonInvestment = true;
    } else {
      this.showRightScrollButtonInvestment = false;
    }

  }

  onAllFundCheckboxChange(e: any) {
    if (e.target.checked == true) {
      this.isHeddaway = true;
    } else {
      this.isHeddaway = false;
    }
  }

  moreBtnClicked() {
    this.moreClicked = !this.moreClicked
  }

  showMovementGraph(data: any) {
    const choice = data;
    switch (choice) {
      case 'aum':
        this.aumChartSelected = true;
        this.returnChartSelected = false;
        break;
      case 'return':
        this.aumChartSelected = false;
        this.returnChartSelected = true;
        break;
      default:
        break;
    }

  }

}
