import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-if-analysis',
  templateUrl: './what-if-analysis.component.html',
  styleUrls: ['./what-if-analysis.component.scss']
})
export class WhatIfAnalysisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
