import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-debt-distribution',
  templateUrl: './debt-distribution.component.html',
  styleUrls: ['./debt-distribution.component.scss', '../alternate-debt.component.scss']
})
export class DebtDistributionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
