import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alternate-equity-allocation',
  templateUrl: './alternate-equity-allocation.component.html',
  styleUrls: ['./alternate-equity-allocation.component.scss', '../alternate-equity.component.scss']
})
export class AlternateEquityAllocationComponent implements OnInit {

  public showFilter = false;
  public showFilterAnimation = false;

  clientList = [];
  selectedItems = [];
  clientSettings = {};

  constructor() { }

  ngOnInit() {
    this.clientList = [
      { 'itemName': 'Equity' },
      { 'itemName': 'Direct Equity' },
      { 'itemName': 'Alternate Equity' }
    ];
    this.clientSettings = {
      singleSelection: true,
      text: 'Direct Equity',
      selectAllText: 'Direct Equity',
      unSelectAllText: 'Direct Equity',
      enableSearchFilter: true,
      classes: 'multiselect-section'
    };
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }

  openFilter() {
    this.showFilter = true;
    this.showFilterAnimation = true;
  }

  closeFilter() {
    this.showFilterAnimation = false;
    setTimeout(() => {
      this.showFilter = false;
    }, 500);
  }

}
