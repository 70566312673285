import { Component, OnInit, ViewChild } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  categoryList = [];
  categorySettings = {};
  productList = [];
  productSettings = {};
  schemeList = [];
  schemeSettings = {};
  priorityList = [];
  prioritySettings = {};
  selectedItems = [];

  public showLeftScrollButton = true;
  public showRightScrollButton = true;

  @ViewChild('resolvedqueriescard', { read: DragScrollComponent }) rq: any;

  constructor() { }

  ngOnInit() {
    this.categoryList = [
      { 'itemName': '' },
    ];
    this.categorySettings = {
      singleSelection: true,
      text: 'Select category',
      selectAllText: '',
      unSelectAllText: '',
      enableSearchFilter: true,
      classes: 'multiselect-section'
    };
    this.productList = [
      { 'itemName': '' },
    ];
    this.productSettings = {
      singleSelection: true,
      text: 'Select Product / Service',
      selectAllText: '',
      unSelectAllText: '',
      enableSearchFilter: true,
      classes: 'multiselect-section'
    };
    this.schemeList = [
      { 'itemName': '' },
    ];
    this.schemeSettings = {
      singleSelection: true,
      text: 'Select Scheme Name',
      selectAllText: '',
      unSelectAllText: '',
      enableSearchFilter: true,
      classes: 'multiselect-section'
    };
    this.priorityList = [
      { 'itemName': '' },
    ];
    this.prioritySettings = {
      singleSelection: true,
      text: 'Select Priority',
      selectAllText: '',
      unSelectAllText: '',
      enableSearchFilter: true,
      classes: 'multiselect-section'
    };
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }

  moveLeft() {
    this.rq.moveLeft();
  }

  moveRight(rightdata) {
    this.rq.moveRight();
  }

  onLeftBound(evn: any) {
    if (evn) {
      this.showLeftScrollButton = true;
    } else {
      this.showLeftScrollButton = false;
    }
  }

  onRightBound(evn: any) {
    if (evn) {
      this.showRightScrollButton = true;
    } else {
      this.showRightScrollButton = false;
    }
  }

}
