import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-repository',
  templateUrl: './repository.component.html',
  styleUrls: ['./repository.component.scss']
})
export class RepositoryComponent implements OnInit {

  clientList = [];
  selectedItems = [];
  clientSettings = {};

  constructor() { }

  ngOnInit() {
    this.clientList = [
      { 'itemName': 'Krishnamurthy Family' },
      { 'itemName': 'Mr. Krisan Krishnamurthy' },
      { 'itemName': 'Mrs. Shreya Krishnamurthy' },
      { 'itemName': 'Mrs. Ray Krishnamurthy' },
      { 'itemName': 'Mrs. Riya Krishnamurthy' },
      { 'itemName': 'Mrs. Sam Krishnamurthy' },
      { 'itemName': 'Mrs. Shyam Krishnamurthy' }
    ];
    this.clientSettings = {
      singleSelection: true,
      text: 'Mr. Krisan Krishnamurthy',
      selectAllText: 'Mr. Krisan Krishnamurthy',
      unSelectAllText: 'Mr. Krisan Krishnamurthy',
      enableSearchFilter: true,
      classes: 'multiselect-section'
    };
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }

}
