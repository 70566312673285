import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Router } from '@angular/router';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  public Highcharts = Highcharts;
  public assetValueGraph: any;
  public portfolioAssetGraph: any;
  public showReportOptions = false;
  public showReportOptionsAnimation = false;

  public AssetsData = [
    {
      name: 'Equity', percent: '15', portfolioValue: '₹162.13 Cr', invested: '₹140.42 Cr', gainloss: '₹21.71 Cr', irr: '8.2', bmirr: '8.3',
      allocationData: [
        { assetname: 'Listed Equity', assetwidth: '40', assetvalue: '₹34.05 Cr' },
        { assetname: 'Equity Mutual Funds', assetwidth: '35', assetvalue: '₹29.16 Cr' },
        { assetname: 'Equity Strategies', assetwidth: '60', assetvalue: '₹58.32 Cr' }
      ]
    },
    {
      name: 'Alternate Equity', percent: '24', portfolioValue: '₹189.15 Cr', invested: '₹182.89 Cr', gainloss: '₹6.26 Cr', irr: '8.4', bmirr: '8.2',
      allocationData: [
        { assetname: 'Alt. Equity Strategies', assetwidth: '55', assetvalue: '₹98.2 Cr' },
        { assetname: 'Unisted Equity', assetwidth: '40', assetvalue: '₹12.8 Cr' }
      ]
    },
    {
      name: 'Debt', percent: '18', portfolioValue: '₹108.09 Cr', invested: '₹107 Cr', gainloss: '₹1.09 Cr', irr: '10.2', bmirr: '9.8',
      allocationData: [
        { assetname: 'Direct Debt', assetwidth: '30', assetvalue: '₹4.03 Cr' },
        { assetname: 'Debt Mutual Funds', assetwidth: '40', assetvalue: '₹15.6 Cr' },
        { assetname: 'REIT-InITs', assetwidth: '37', assetvalue: '₹9.78 Cr' },
        { assetname: 'Absolute Return Strat.', assetwidth: '25', assetvalue: '₹9.78 Cr' }
      ]
    },
    {
      name: 'Alternate Debt', percent: '20', portfolioValue: '₹27 Cr', invested: '₹23 Cr', gainloss: '₹5 Cr', irr: '22.6', bmirr: '21.5',
      allocationData: [
        { assetname: 'Cash Mutual Fund', assetwidth: '40', assetvalue: '₹54.04 Cr' }
      ]
    },
    {
      name: 'Cash', percent: '14', portfolioValue: '₹54.04 Cr', invested: '₹40.10 Cr', gainloss: '₹14.14 Cr', irr: '7.8', bmirr: '7.5',
      allocationData: [
        { assetname: 'Alt. Debt Strategies', assetwidth: '45', assetvalue: '₹27 Cr' }
      ]
    },
    {
      name: 'Others', percent: '9', portfolioValue: '₹24.02 Cr', invested: '₹24 Cr', gainloss: '₹0.02 Cr', irr: '4.4', bmirr: '4.1',
      allocationData: [
        { assetname: 'Commodities Strategies', assetwidth: '30', assetvalue: '₹4.02 Cr' },
        { assetname: 'Real Estate Strategies', assetwidth: '40', assetvalue: '₹12 Cr' },
        { assetname: 'Offshore Strategies', assetwidth: '37', assetvalue: '₹8 Cr' },
      ]
    }
  ]

  constructor(private router: Router) { }

  ngOnInit() {
    this.genrateAssetValueSummary();
    this.genratePortfolioAssetSummary();
  }

  genrateAssetValueSummary() {
    this.assetValueGraph = {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        backgroundColor: null,
        // align: 'top',
        type: 'pie',
        spacing: [-50, 0, -50, 0],
        // marginTop: -70,
        // width: 250,
        // height: 280,
        style: {
          fontFamily: 'IBMPlexSans'
        },
        height: 280
      },
      title: {
        verticalAlign: 'middle',
        horizontalAlign: 'middle',
        y: -8,
        // floating: true,
        useHTML: true,
        text: '<span class="portfolio-asset-value-title">Asset Portfolio<br />Value</span>',
        style: {
          fontSize: '16px',
          color: '#757575',
          fontWeight: '600'
        }
      },
      tooltip: {
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-value">₹81.06 Cr</span><br /><span class="tooltip-name font-size-12">15% Equity</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          cursor: 'pointer',
          colors: [
            '#FB7648',
            '#EAC2D0',
            '#556594',
            '#A2CFEE',
            '#8BCBA7',
            '#DFD7D7'
          ],
          // enableMouseTracking: false,
          dataLabels: {
            enabled: false
          },
          center: ['50%', '50%'],
          showInLegend: true,
          innerSize: '91%',
          size: '72%',
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [{
        type: 'pie',
        innerSize: '91%',
        data: [
          { name: 'Equity', y: 35 },
          { name: 'Alternate Equity', y: 15 },
          { name: 'Debt', y: 10 },
          { name: 'Alternate Debt', y: 8 },
          { name: 'Cash & Equiv', y: 22 },
          { name: 'Alternate', y: 10 }
        ]
      }],
    }
  }

  genratePortfolioAssetSummary() {
    this.portfolioAssetGraph = {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        backgroundColor: null,
        type: 'pie',
        spacing: [0, 0, 0, 0],
        // width: 75,
        // height: 75,
        style: {
          fontFamily: 'IBMPlexSans'
        }
      },
      title: {
        verticalAlign: 'middle',
        y: 4,
        // x: -3,
        useHTML: true,
        text: '<span class="portfolio-asset-value-title">15%</span>',
        style: {
          fontSize: '16px',
          color: '#000000',
          fontWeight: '600'
        }
      },
      tooltip: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          cursor: 'pointer',
          colors: [
            '#ffffff',
            'rgba(0, 0, 0, 0.1)'
          ],
          // enableMouseTracking: false,
          dataLabels: {
            enabled: false
          },
          center: ['50%', '50%'],
          showInLegend: true,
          innerSize: '85%',
          size: '155%'
        },
        series: {
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [{
        type: 'pie',
        innerSize: '85%',
        data: [
          { name: 'Equity', y: 15 },
          { name: '', y: 85 }
        ]
      }],

      responsive: {
        rules: [{
          condition: {
            maxWidth: 66
          },
          chartOptions: {
            title: {
              style: {
                fontSize: '12px',
                color: '#000000',
                fontWeight: '600'
              }
            },
          }
        }]
      }
    }
  }

  showAssetDetails(data) {
    if (data === 'Equity') {
      this.router.navigate(['portfolio/equity']);
    }
    else if (data === 'Alternate Equity') {
      this.router.navigate(['portfolio/alternate-equity']);
    }
    else if (data === 'Debt') {
      this.router.navigate(['portfolio/debt']);
    }
    else if (data === 'Alternate Debt') {
      this.router.navigate(['portfolio/alternate-debt']);
    }
    else if (data === 'Cash') {
      this.router.navigate(['portfolio/cash']);
    }
    else if (data === 'Others') {
      this.router.navigate(['portfolio/others']);
    }
  }

  openReportoptions() {
    this.showReportOptions = true;
    this.showReportOptionsAnimation = true;
  }

  closeReportOptions() {
    this.showReportOptionsAnimation = false;
    setTimeout(() => {
      this.showReportOptions = false;
    }, 500);
  }

}
