import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-rm-product-details',
  templateUrl: './rm-product-details.component.html',
  styleUrls: ['./rm-product-details.component.scss', '../rm-products.component.scss']
})
export class RMProductDetailsComponent implements OnInit {

  public Highcharts = Highcharts;
  public productGraph: any;
  public performanceGraph: any;
  public product = [
    {
      name: 'Equity', color: '#FB7648'
    },
    {
      name: 'Alternate Equity', color: '#EAC2D0'
    },
    {
      name: 'Debt', color: '#556594'
    },
    {
      name: 'Alternate Debt', color: '#A2CFEE'
    },
    {
      name: 'Cash', color: '#8BCBA7'
    },
    {
      name: 'Other', color: '#DEDEDE'
    }
  ]

  constructor() { }

  ngOnInit() {
    this.genrateProductGraphSummary();
    this.genratePerformanceGraphSummary();
  }

  genrateProductGraphSummary() {
    this.productGraph = {
      chart: {
        type: 'spline',
        width: 92,
        height: 30,
        spacing: [0, 0, 0, 0],
        backgroundColor: null
      },
      yAxis: {
        startOnTick: false,
        min: -5,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: null
        },
        ordinal: false,
        minorTickLength: 0,
        tickLength: 0,
        crosshair: false
      },
      xAxis: {
        margin: 10,
        lineWidth: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: null
        },
        ordinal: false,
        minorTickLength: 0,
        tickLength: 0,
        crosshair: false
      },
      title: {
        useHTML: true,
        text: ''
      },
      subtitle: { text: null },
      tooltip: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      exporting: { enabled: false },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            lineWidth: 1,
          },
          fillColor: {
            linearGradient: [0, 0, 0, 0],
            stops: [
              [0, 'rgba(255, 255, 255, 0.0)'],
              [1, 'rgba(255, 255, 255, 0.0)']
            ]
          }
        }
      },
      series: [{
        data: [0, 0, 0, 20, 26, 20, 0, 0, 0]
      }, {
        data: [0, 0, 0, 0, 8, 12, 8, 0, 0]
      }],
    }
  }

  genratePerformanceGraphSummary() {
    this.performanceGraph = {
      chart: {
        type: 'column',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        spacing: [50, 0, 0, 0],
        style: {
          fontFamily: 'IBMPlexSans'
        },
        // width: 750,
        height: 300
      },
      title: {
        text: ''
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        lineWidth: 0,
        tickLength: 0,
        type: 'category',
        style: {
          color: '#757575',
          fontSize: '10px'
        }
      },
      yAxis: {
        tickAmount: 10,
        plotLines: [
          {
            color: '#c8c8c8',
            width: 1,
            value: 0
          }, {
            color: '#c8c8c8',
            width: 1,
            value: 4
          }, {
            color: '#c8c8c8',
            width: 1,
            value: 8
          }, {
            color: '#c8c8c8',
            width: 1,
            value: 12
          }, {
            color: '#c8c8c8',
            width: 1,
            value: 16
          }, {
            color: '#c8c8c8',
            width: 1,
            value: 20
          }, {
            color: '#c8c8c8',
            width: 1,
            value: 24
          }, {
            color: '#c8c8c8',
            width: 1,
            value: 28
          }],
        gridLineWidth: 0,
        title: {
          text: '',
        },
        labels: {
          formatter() {
            if (this.value < 0) {
              return "";
            } else if (this.value == 28) {
              return "";
            }
            return (this.value) + "%";
          },
          x: 4,
          y: -5,
          align: 'left',
          style: {
            color: '#757575',
            fontSize: '12px'
          }
        }

      },
      legend: {
        useHTML: true,
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'top',
        // x: 0,
        y: -45,
        floating: true,
        borderWidth: 0,
        enabled: true,
        symbolHeight: 14,
        symbolWidth: 14,
        symbolRadius: 2,
        itemStyle: {
          color: '#000',
          fontWeight: '400',
          lineHeight: "12px"
        },
      },
      plotOptions: {
        series: {
          groupPadding: 0.25,
          pointPadding: 0,
          pointWidth: 40,
          borderWidth: 0,
          dataLabels: {
            enabled: false
          }
        }
      },
      tooltip: {
        headerFormat: null,
        backgroundColor: '#000',
        borderRadius: 6,
        borderColor: '#000',
        width: 100,
        useHTML: true,
        formatter: function (data: any) {
          return '<span class="tooltip-value font-size-16">' + this.y + '%</span><br /><span class="tooltip-name font-size-10">Jan 2021</span>';
        },
        style: {
          textAlign: 'center',
          color: '#fff',
          width: 100,
        }
      },
      series: [{
        name: 'Scheme IRR',
        color: '#4891FF',
        data: [-2, 14.21, 26, 20]
      }, {
        name: 'Benchmark IRR',
        color: '#000000',
        data: [-6, 2, 3, 6]
      }],

      responsive: {
        rules: [{
          condition: {
            maxWidth: 660
          },
          chartOptions: {
            plotOptions: {
              series: {
                pointWidth: 20,
              }
            }
          }
        }, {
          condition: {
            maxWidth: 425
          },
          chartOptions: {
            legend: {
              useHTML: true,
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
              y: 40
            },
          }
        },
        {
          condition: {
            maxWidth: 400
          },
          chartOptions: {
            plotOptions: {
              series: {
                pointWidth: 10,
              }
            }
          }
        }]
      }
    }
  }

}
