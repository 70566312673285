import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* tslint:disable */
// components
import { DashboardComponent } from './view/main/dashboard/dashboard.component';
import { LoginComponent } from './view/authentication/login/login.component';
import { RepositoryComponent } from './view/main/repository/repository.component';
import { ReportsComponent } from './view/main/reports/reports.component';
import { SettingsComponent } from './view/main/settings/settings.component';
import { SupportComponent } from './view/main/support/support.component';
import { PlannerComponent } from './view/main/planner/planner.component';
import { TransactionsComponent } from './view/main/transactions/transactions.component';
import { ProfileComponent } from './view/main/profile/profile.component';
import { ProductsComponent } from './view/main/products/products.component';
import { ProductDetailsComponent } from './view/main/products/product-details/product-details.component';
import { InsightsComponent } from './view/main/insights/insights.component';
import { InsightsDetailsComponent } from './view/main/insights/insights-details/insights-details.component';
import { PortfolioComponent } from './view/main/portfolio/portfolio.component';
import { ConsolidatedHoldingsComponent } from './view/main/portfolio/consolidated-holdings/consolidated-holdings.component';
import { WhatIfAnalysisComponent } from './view/main/portfolio/what-if-analysis/what-if-analysis.component';
import { EquityComponent } from './view/main/portfolio/equity/equity.component';
import { FundDetailsComponent } from './view/main/portfolio/fund-details/fund-details.component';
import { DebtComponent } from './view/main/portfolio/debt/debt.component';
import { AlternateEquityComponent } from './view/main/portfolio/alternate-equity/alternate-equity.component';
import { CashComponent } from './view/main/portfolio/cash/cash.component';
import { AlternateDebtComponent } from './view/main/portfolio/alternate-debt/alternate-debt.component';
import { OthersComponent } from './view/main/portfolio/others/others.component';

// RM PATH
import { RmDashboardComponent } from './view/rm/rm-dashboard/rm-dashboard.component';
import { RMMeetingComponent } from './view/rm/rm-meeting/rm-meeting.component';
import { RMMeetingNoteComponent } from './view/rm/rm-meeting/rm-meetingnote/meeting-note.component';
import { RMClientComponent } from './view/rm/rm-client/rm-client.component';
import { RMProductsComponent } from './view/rm/rm-products/rm-products.component';
import { RMProductDetailsComponent } from './view/rm/rm-products/rm-product-details/rm-product-details.component';
import { RMTransactionsComponent } from './view/rm/rm-transactions/rm-transactions.component';
import { RmReportsComponent } from './view/rm/rm-reports/rm-reports.component';
import { DetailedReportComponent } from './view/rm/rm-reports/detailed-report/detailed-report.component';
import { RmRepositoryComponent } from './view/rm/rm-repository/rm-repository.component';
import { DetailedRepositoryComponent } from './view/rm/rm-repository/detailed-repository/detailed-repository.component';
import { RmIpgComponent } from './view/rm/rm-ipg/rm-ipg.component';
import { IpgDetailsComponent } from './view/rm/rm-ipg/ipg-details/ipg-details.component';
import { RmInsightsComponent } from './view/rm/rm-insights/rm-insights.component';
import { RmInsightsDetailsComponent } from './view/rm/rm-insights/rm-insights-details/rm-insights-details.component';

// const routes: Routes = [];


const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'dashboard',
  //   pathMatch: 'full',
  //   // data: { title: 'Dashboard' },
  // },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { title: 'Dashboard' },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login' },
  },
  {
    path: 'reports',
    component: ReportsComponent,
    data: { title: 'Reports' },
  },
  {
    path: 'repository',
    component: RepositoryComponent,
    data: { title: 'Repository' },
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { title: 'Settings' },
  },
  {
    path: 'support',
    component: SupportComponent,
    data: { title: 'Support' },
  },
  {
    path: 'planner',
    component: PlannerComponent,
    data: { title: 'Planner' },
  },
  {
    path: 'transactions',
    component: TransactionsComponent,
    data: { title: 'Transactions' },
  },
  {
    path: 'profile',
    component: ProfileComponent,
    data: { title: 'Profile' },
  },
  {
    path: 'products',
    component: ProductsComponent,
    data: { title: 'Products' },
  },
  {
    path: 'products/product-details',
    component: ProductDetailsComponent,
    data: { title: 'Product Details' },
  },
  {
    path: 'insights',
    component: InsightsComponent,
    data: { title: 'Insights' },
  },
  {
    path: 'insights/insights-details',
    component: InsightsDetailsComponent,
    data: { title: 'Insights' },
  },
  {
    path: 'portfolio',
    component: PortfolioComponent,
    data: { title: 'Portfolio' },
  },
  {
    path: 'portfolio/consolidated-holdings',
    component: ConsolidatedHoldingsComponent,
    data: { title: 'Consolidated Holdings' },
  },
  {
    path: 'portfolio/what-if-analysis',
    component: WhatIfAnalysisComponent,
    data: { title: 'What-If Analysis' },
  },
  {
    path: 'portfolio/equity',
    component: EquityComponent,
    data: { title: 'Equity' },
  },
  {
    path: 'portfolio/debt',
    component: DebtComponent,
    data: { title: 'Debt' },
  },
  {
    path: 'portfolio/alternate-equity',
    component: AlternateEquityComponent,
    data: { title: 'Alternate Equity' },
  },
  {
    path: 'portfolio/cash',
    component: CashComponent,
    data: { title: 'Cash' },
  },
  {
    path: 'portfolio/alternate-debt',
    component: AlternateDebtComponent,
    data: { title: 'Alternate Debt' },
  },
  {
    path: 'portfolio/others',
    component: OthersComponent,
    data: { title: 'Others' },
  },
  {
    path: 'portfolio/fund-details',
    component: FundDetailsComponent,
    data: { title: 'Fund Details' },
  },

  // Rm components

  {
    path: 'rm/dashboard',
    component: RmDashboardComponent,
    data: { title: 'Dashboard' },
  },
  {
    path: 'rm/meeting',
    component: RMMeetingComponent,
    data: { title: 'Meeting' },
  },
  {
    path: 'rm-meeting/rm-meeting-note',
    component: RMMeetingNoteComponent,
    data: { title: 'Meeting Note' },
  },
  {
    path: 'rm/clients',
    component: RMClientComponent,
    data: { title: 'RM Client' },
  },
  {
    path: 'rm/products',
    component: RMProductsComponent,
    data: { title: 'RM Products' },
  },
  {
    path: 'rm/products/product-details',
    component: RMProductDetailsComponent,
    data: { title: 'RM Product Details' },
  },
  {
    path: 'rm/transactions',
    component: RMTransactionsComponent,
    data: { title: 'RM Transactions' },
  },
  {
    path: 'rm/reports',
    component: RmReportsComponent,
    data: { title: 'Reports' },
  },
  {
    path: 'rm/detailed-reports',
    component: DetailedReportComponent,
    data: { title: 'Reports' },
  },
  {
    path: 'rm/repository',
    component: RmRepositoryComponent,
    data: { title: 'Repository' },
  },
  {
    path: 'rm/detailed-repository',
    component: DetailedRepositoryComponent,
    data: { title: 'Repository' },
  },
  {
    path: 'rm/ipg',
    component: RmIpgComponent,
    data: { title: 'IPG' },
  },
  {
    path: 'rm/ipg-details',
    component: IpgDetailsComponent,
    data: { title: 'IPG' },
  },
  {
    path: 'rm/insights',
    component: RmInsightsComponent,
    data: { title: 'Insights ' },
  },
  {
    path: 'rm/insights/insights-details',
    component: RmInsightsDetailsComponent,
    data: { title: 'Insights ' },
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})


export class AppRoutingModule { }
