import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alternate-debt',
  templateUrl: './alternate-debt.component.html',
  styleUrls: ['./alternate-debt.component.scss']
})
export class AlternateDebtComponent implements OnInit {

  public showFilter = false;
  public showFilterAnimation = false;

  constructor() { }

  ngOnInit() {
  }

  openFilter() {
    this.showFilter = true;
    this.showFilterAnimation = true;
  }

  closeFilter() {
    this.showFilterAnimation = false;
    setTimeout(() => {
      this.showFilter = false;
    }, 500);
  }

}
