import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detailed-report',
  templateUrl: './detailed-report.component.html',
  styleUrls: ['./detailed-report.component.scss']
})
export class DetailedReportComponent implements OnInit {

  public detailedReportsData = [
    { name: 'Financial year 2020 - 2021', date: '31 Mar 2021', details: 'Formal records of financial activities and position', size: '5.98 Kb' }
  ]

  public individualReportsData = [
    { name: 'Holdings Report', date: '05 Jul 2021', details: 'Report of how different allocations are performing', size: '5.98 Kb' },
    { name: 'Portfolio Analytics Report', date: '02 Jul 2021', details: 'Analytical report of overall portfolio performance', size: '2.76 Kb' },
    { name: 'Cashflow Report', date: '28 Jun 2021', details: 'Changes in accounts & income affect cash and equvi.', size: '19.61 Kb' },
    { name: 'Transactions Report', date: '28 Jun 2021', details: 'Detailed billing information of each transaction', size: '3.76 Kb' },
    { name: 'Corporate Action Report', date: '17 Jun 2021', details: 'Company events materially impacting stakeholders', size: '5.28 Kb' },
    { name: 'Capital Gains Report', date: '02 Jun 2021', details: 'The profits earned on the sale of an asset', size: '2.75 Kb' }
  ]

  public previousReportDetails = [
    {
      year: '2019-2020',
      date: '1 Apr 2019 – 31 Mar 2020',
      reports: [
        {
          reportname: 'Detailed Report',
          reportdate: '5 Jul 2020 • 5.98 Kb'
        },
        {
          reportname: 'Capital Gains Report',
          reportdate: '5 Jul 2020 • 5.98 Kb'
        }
      ]
    },
    {
      year: '2018-2019',
      date: '1 Apr 2018 – 31 Mar 2019',
      reports: [
        {
          reportname: 'Detailed Report',
          reportdate: '5 Jul 2019 • 5.98 Kb'
        },
        {
          reportname: 'Capital Gains Report',
          reportdate: '5 Jul 2019 • 5.98 Kb'
        }
      ]
    },
  ]

  constructor() { }

  ngOnInit() {
  }

}
