import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { ChartModule } from 'angular2-highcharts';
// import * as highcharts from 'Highcharts';
import { DragScrollModule } from 'ngx-drag-scroll';
import { HighchartsChartModule } from 'highcharts-angular';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './view/common/menu/menu.component';
import { MainComponent } from './view/main/main.component';
import { DashboardComponent } from './view/main/dashboard/dashboard.component';
import { AdvisorWiseSummaryComponent } from './view/main/dashboard/advisor-wise-summary/advisor-wise-summary.component';
import { AuthenticationComponent } from './view/authentication/authentication.component';
import { LoginComponent } from './view/authentication/login/login.component';
import { HeaderComponent } from './view/common/header/header.component';
import { RepositoryComponent } from './view/main/repository/repository.component';
import { ReportsComponent } from './view/main/reports/reports.component';
import { SettingsComponent } from './view/main/settings/settings.component';
import { SupportComponent } from './view/main/support/support.component';
import { PlannerComponent } from './view/main/planner/planner.component';
import { TransactionsComponent } from './view/main/transactions/transactions.component';
import { ProfileComponent } from './view/main/profile/profile.component';
import { ProductsComponent } from './view/main/products/products.component';
import { ProductDetailsComponent } from './view/main/products/product-details/product-details.component';
import { InsightsComponent } from './view/main/insights/insights.component';
import { InsightsDetailsComponent } from './view/main/insights/insights-details/insights-details.component';
import { PortfolioComponent } from './view/main/portfolio/portfolio.component';
import { ConsolidatedHoldingsComponent } from './view/main/portfolio/consolidated-holdings/consolidated-holdings.component';
import { WhatIfAnalysisComponent } from './view/main/portfolio/what-if-analysis/what-if-analysis.component';
import { EquityComponent } from './view/main/portfolio/equity/equity.component';
import { EquityAllocationComponent } from './view/main/portfolio/equity/equity-allocation/equity-allocation.component';
import { SectorAndMarketcapComponent } from './view/main/portfolio/equity/sector-and-marketcap/sector-and-marketcap.component';
import { EquityExposureComponent } from './view/main/portfolio/equity/equity-exposure/equity-exposure.component';
import { EquityQuantsComponent } from './view/main/portfolio/equity/equity-quants/equity-quants.component';
import { FundDetailsComponent } from './view/main/portfolio/fund-details/fund-details.component';
import { DebtComponent } from './view/main/portfolio/debt/debt.component';
import { DebtAllocationComponent } from './view/main/portfolio/debt/debt-allocation/debt-allocation.component';
import { DebtExposureComponent } from './view/main/portfolio/debt/debt-exposure/debt-exposure.component';
import { DebtQuantsComponent } from './view/main/portfolio/debt/debt-quants/debt-quants.component';
import { CreditQualityAndMaturityComponent } from './view/main/portfolio/debt/credit-quality-and-maturity/credit-quality-and-maturity.component';
import { AlternateEquityComponent } from './view/main/portfolio/alternate-equity/alternate-equity.component';
import { AlternateEquityAllocationComponent } from './view/main/portfolio/alternate-equity/alternate-equity-allocation/alternate-equity-allocation.component';
import { AlternateEquityDistributionComponent } from './view/main/portfolio/alternate-equity/alternate-equity-distribution/alternate-equity-distribution.component';
import { AlternateEquityDrawdownsComponent } from './view/main/portfolio/alternate-equity/alternate-equity-drawdowns/alternate-equity-drawdowns.component';
import { AlternateEquityPerformanceComponent } from './view/main/portfolio/alternate-equity/alternate-equity-performance/alternate-equity-performance.component';
import { CashComponent } from './view/main/portfolio/cash/cash.component';
import { CashAllocationComponent } from './view/main/portfolio/cash/cash-allocation/cash-allocation.component';
import { CashCreditQualityAndMaturityComponent } from './view/main/portfolio/cash/cash-credit-quality-and-maturity/cash-credit-quality-and-maturity.component';
import { CashExposureComponent } from './view/main/portfolio/cash/cash-exposure/cash-exposure.component';
import { CashQuantsComponent } from './view/main/portfolio/cash/cash-quants/cash-quants.component';
import { AlternateDebtComponent } from './view/main/portfolio/alternate-debt/alternate-debt.component';
import { OthersComponent } from './view/main/portfolio/others/others.component';
import { DebtDrawdownComponent } from './view/main/portfolio/alternate-debt/debt-drawdown/debt-drawdown.component';
import { DebtDistributionComponent } from './view/main/portfolio/alternate-debt/debt-distribution/debt-distribution.component';
import { AlternateDebtAllocationComponent } from './view/main/portfolio/alternate-debt/alternate-debt-allocation/alternate-debt-allocation.component';
import { OthersAllocationComponent } from './view/main/portfolio/others/others-allocation/others-allocation.component';

// RM PATH
import { RmComponent } from './view/rm/rm.component';
import { RmDashboardComponent } from './view/rm/rm-dashboard/rm-dashboard.component';
import { RMMeetingComponent } from './view/rm/rm-meeting/rm-meeting.component';
import { RMMeetingNoteComponent } from './view/rm/rm-meeting/rm-meetingnote/meeting-note.component';
import { RMClientComponent } from './view/rm/rm-client/rm-client.component';
import { RMProductsComponent } from './view/rm/rm-products/rm-products.component';
import { RMProductDetailsComponent } from './view/rm/rm-products/rm-product-details/rm-product-details.component';
import { RMTransactionsComponent } from './view/rm/rm-transactions/rm-transactions.component';
import { RmReportsComponent } from './view/rm/rm-reports/rm-reports.component';
import { DetailedReportComponent } from './view/rm/rm-reports/detailed-report/detailed-report.component';
import { RmRepositoryComponent } from './view/rm/rm-repository/rm-repository.component';
import { DetailedRepositoryComponent } from './view/rm/rm-repository/detailed-repository/detailed-repository.component';
import { RmIpgComponent } from './view/rm/rm-ipg/rm-ipg.component';
import { IpgDetailsComponent } from './view/rm/rm-ipg/ipg-details/ipg-details.component';
import { RmInsightsComponent } from './view/rm/rm-insights/rm-insights.component';
import { RmInsightsDetailsComponent } from './view/rm/rm-insights/rm-insights-details/rm-insights-details.component';


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    MainComponent,
    DashboardComponent,
    AdvisorWiseSummaryComponent,
    AuthenticationComponent,
    LoginComponent,
    HeaderComponent,
    RepositoryComponent,
    ReportsComponent,
    SettingsComponent,
    SupportComponent,
    PlannerComponent,
    TransactionsComponent,
    ProfileComponent,
    ProductsComponent,
    ProductDetailsComponent,
    InsightsComponent,
    InsightsDetailsComponent,
    PortfolioComponent,
    ConsolidatedHoldingsComponent,
    WhatIfAnalysisComponent,
    EquityComponent,
    EquityAllocationComponent,
    SectorAndMarketcapComponent,
    EquityExposureComponent,
    EquityQuantsComponent,
    FundDetailsComponent,
    DebtComponent,
    DebtAllocationComponent,
    DebtExposureComponent,
    DebtQuantsComponent,
    CreditQualityAndMaturityComponent,
    AlternateEquityComponent,
    AlternateEquityAllocationComponent,
    AlternateEquityDistributionComponent,
    AlternateEquityDrawdownsComponent,
    AlternateEquityPerformanceComponent,
    CashComponent,
    CashAllocationComponent,
    CashCreditQualityAndMaturityComponent,
    CashExposureComponent,
    CashQuantsComponent,
    AlternateDebtComponent,
    OthersComponent,
    DebtDrawdownComponent,
    DebtDistributionComponent,
    AlternateDebtAllocationComponent,
    OthersAllocationComponent,
    RmComponent,
    RmDashboardComponent,
    RMMeetingComponent,
    RMMeetingNoteComponent,
    RMClientComponent,
    RMProductsComponent,
    RMProductDetailsComponent,
    RMTransactionsComponent,
    RmReportsComponent,
    DetailedReportComponent,
    RmRepositoryComponent,
    DetailedRepositoryComponent,
    RmIpgComponent,
    IpgDetailsComponent,
    RmInsightsComponent,
    RmInsightsDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // ChartModule.forRoot(highcharts),,
    HighchartsChartModule,
    DragScrollModule,
    AngularMultiSelectModule,
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
