import { Component, OnInit, ViewChild } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll/lib';

@Component({
  selector: 'app-rm-transactions',
  templateUrl: './rm-transactions.component.html',
  styleUrls: ['./rm-transactions.component.scss']
})
export class RMTransactionsComponent implements OnInit {

  @ViewChild('meetingcard', { read: DragScrollComponent }) pc: any;

  clientList = [];
  selectedItems = [];
  clientSettings = {};
  securityNameList = [];
  securityNameSettings = {};
  public showLeftScrollButton = true;
  public showRightScrollButton = true;

  public userDataShow = false;

  // popup animation

  public showFilter = false;
  public showFilterAnimation = false;

  constructor() { }

  ngOnInit() {
    this.clientList = [
      { 'itemName': 'Krishnamurthy Family' },
      { 'itemName': '' },
    ];
    this.clientSettings = {
      singleSelection: true,
      text: '',
      selectAllText: '',
      unSelectAllText: '',
      enableSearchFilter: true,
      classes: 'multiselect-section'
    };
    this.securityNameList = [
      { 'itemName': 'Krishnamurthy Family' },
      { 'itemName': '' },
    ];
    this.securityNameSettings = {
      singleSelection: true,
      text: '',
      selectAllText: '',
      unSelectAllText: '',
      enableSearchFilter: true,
      classes: 'multiselect-section'
    };
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }

  showOption() {
    this.userDataShow = !this.userDataShow;
  }

  openFilter() {
    this.showFilter = true;
    this.showFilterAnimation = true;
  }

  closeFilter() {
    this.showFilterAnimation = false;
    setTimeout(() => {
      this.showFilter = false;
    }, 500);
  }

  moveLeft(leftdata) {
    this.pc.moveLeft();
  }

  moveRight(rightdata) {
    this.pc.moveRight();
  }

  onLeftBound(evn: any) {
    if (evn) {
      this.showLeftScrollButton = true;
    } else {
      this.showLeftScrollButton = false;
    }
  }

  onRightBound(evn: any) {
    if (evn) {
      this.showRightScrollButton = true;
    } else {
      this.showRightScrollButton = false;
    }
  }

  showDetailedData() {
    this.userDataShow = true;
  }

}




