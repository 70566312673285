import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  public Highcharts = Highcharts;
  public productGraph: any;
  public product = [
    {
      name: 'Equity', color: '#FB7648'
    },
    {
      name: 'Alternate Equity', color: '#EAC2D0'
    },
    {
      name: 'Debt', color: '#556594'
    },
    {
      name: 'Alternate Debt', color: '#A2CFEE'
    },
    {
      name: 'Cash', color: '#8BCBA7'
    },
    {
      name: 'Other', color: '#DEDEDE'
    }
  ]

  productList = [];
  selectedItems = [];
  productSettings = {};

  constructor() { }

  ngOnInit() {
    this.productList = [
      { 'itemName': 'All Asset Classes' },
      { 'itemName': 'All Asset Classes' },
      { 'itemName': 'All Asset Classes' },
      { 'itemName': 'All Asset Classes' }
    ];
    this.productSettings = {
      singleSelection: true,
      text: 'All Asset Classes',
      selectAllText: 'All Asset Classes',
      unSelectAllText: 'All Asset Classes',
      enableSearchFilter: true,
      classes: 'multiselect-section'
    };


    this.genrateProductGraphSummary();
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }

  genrateProductGraphSummary() {
    this.productGraph = {
      chart: {
        type: 'spline',
        width: 92,
        height: 40,
        spacing: [0, 0, 0, 0],
        backgroundColor: null
      },
      yAxis: {
        startOnTick: false,
        min: -5,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: null
        },
        ordinal: false,
        minorTickLength: 0,
        tickLength: 0,
        crosshair: false
      },
      xAxis: {
        margin: 10,
        lineWidth: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: null
        },
        ordinal: false,
        minorTickLength: 0,
        tickLength: 0,
        crosshair: false
      },
      title: {
        useHTML: true,
        text: ''
      },
      subtitle: { text: null },
      tooltip: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      exporting: { enabled: false },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            lineWidth: 1,
          },
          fillColor: {
            linearGradient: [0, 0, 0, 0],
            stops: [
              [0, 'rgba(255, 255, 255, 0.0)'],
              [1, 'rgba(255, 255, 255, 0.0)']
            ]
          }
        }
      },
      series: [{
        color: '#DD4814',
        data: [0, 0, 0, 20, 26, 20, 0, 0, 0]
      }, {
        color: '#DD4814',
        data: [0, 0, 0, 0, 8, 12, 8, 0, 0]
      }]
    }
  }

}
