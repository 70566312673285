import { Component, OnInit, ViewChild } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll/lib';

@Component({
  selector: 'app-rm-meeting',
  templateUrl: './rm-meeting.component.html',
  styleUrls: ['./rm-meeting.component.scss']
})
export class RMMeetingComponent implements OnInit {

  @ViewChild('meetingcard', { read: DragScrollComponent }) pc: any;

  agendaList = [];
  agendaSettings = {};
  monthSettings = {};
  productList = [];
  productSettings = {};
  selectedItems = [];
  public showLeftScrollButton = true;
  public showRightScrollButton = true;

  constructor() { }

  ngOnInit() {
    this.agendaList = [
      { 'itemName': '' },
    ];
    this.productList = [
      { 'itemName': '' },
    ];

    this.agendaSettings = {
      singleSelection: true,
      text: 'Select Agenda',
      selectAllText: '',
      unSelectAllText: '',
      enableSearchFilter: true,
      classes: 'multiselect-section'
    };

    this.monthSettings = {
      singleSelection: true,
      text: 'This Month',
      selectAllText: '',
      unSelectAllText: '',
      enableSearchFilter: true,
      classes: 'multiselect-section'
    };

    this.productSettings = {
      singleSelection: true,
      text: 'Select Product',
      selectAllText: '',
      unSelectAllText: '',
      enableSearchFilter: true,
      classes: 'multiselect-section'
    };
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }

  moveLeft(leftdata) {
    this.pc.moveLeft();
  }

  moveRight(rightdata) {
    this.pc.moveRight();
  }

  onLeftBound(evn: any) {
    if (evn) {
      this.showLeftScrollButton = true;
    } else {
      this.showLeftScrollButton = false;
    }
  }

  onRightBound(evn: any) {
    if (evn) {
      this.showRightScrollButton = true;
    } else {
      this.showRightScrollButton = false;
    }
  }

}
