import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Output() public titlecomponent = new EventEmitter<string>();

  public menuCollapsed = false;
  public clientMenu = true;
  public rmMenu = false;

  constructor(
    private router: Router,
  ) {
    router.events.subscribe((event: any) => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.data.title) {
          this.titlecomponent.emit(event.snapshot.data.title);
        }
      }
    });
  }

  ngOnInit() {
  }

  isCollapsed() {
    this.menuCollapsed = !this.menuCollapsed;
  }

}
