import { Component, OnInit, ViewChild } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll/lib';

@Component({
  selector: 'app-rm-insights-details',
  templateUrl: './rm-insights-details.component.html',
  styleUrls: ['./rm-insights-details.component.scss', '../rm-insights.component.scss']
})
export class RmInsightsDetailsComponent implements OnInit {

  public showLeftScrollButton = true;
  public showRightScrollButton = true;

  @ViewChild('articlecard', { read: DragScrollComponent }) article: any;

  constructor() { }

  ngOnInit() {
  }

  moveLeft() {
    this.article.moveLeft();
  }

  moveRight(rightdata) {
    this.article.moveRight();
  }

  onLeftBound(evn: any) {
    if (evn) {
      this.showLeftScrollButton = true;
    } else {
      this.showLeftScrollButton = false;
    }
  }

  onRightBound(evn: any) {
    if (evn) {
      this.showRightScrollButton = true;
    } else {
      this.showRightScrollButton = false;
    }
  }


}
